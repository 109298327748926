
import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Outlet, Route, Routes } from 'react-router-dom';
import AppProviders from '../ReactUI/AppProviders';
import ApplicationStateProvider from '../ReactUI/components/ApplicationStateProvider';
import { BrowserFallBackToHashRouter } from '../ReactUI/components/BrowserFallBackToHashRouter';
import ViewLoading from '../ReactUI/components/ViewLoading';
import { ImportTransactionsView } from './ImportTransactionsView';

export const ImportTransactionsApp = (element: HTMLElement) => {
    const root = ReactDOM.createRoot(element);

    root.render(
        <ApplicationStateProvider>
            <BrowserFallBackToHashRouter>
                <AppProviders>
                    <Suspense fallback={<ViewLoading />}>
                        <Outlet />
                        <Routes>
                            <Route
                                path="/import-transactions/*"
                                element={
                                    <Suspense fallback={<ViewLoading />}>
                                        <Outlet />
                                        <Routes>
                                            <Route path=":id" element={<ImportTransactionsView />} />
                                        </Routes>
                                    </Suspense>
                                }
                            />
                        </Routes>
                    </Suspense>
                </AppProviders>
            </BrowserFallBackToHashRouter>
        </ApplicationStateProvider>
    );

    return {
        unmount: () => root.unmount(),
    };
};
