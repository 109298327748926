import { Logger } from '../../Logger';
import { TABLE_NAME_CURRENT } from './TABLE_NAME_CURRENT';
import { TABLE_NAME_LEGACY } from './TABLE_NAME_LEGACY';

export const migrateDataFromLegacyTable = async (db: SQLitePlugin.Database): Promise<void> => {
    await new Promise<void>(resolve => {
        db.executeSql(
            `INSERT OR REPLACE INTO ${TABLE_NAME_CURRENT} (id, jsonData) SELECT id, jsonData FROM ${TABLE_NAME_LEGACY} WHERE jsonData IS NOT NULL`,
            [],
            () => {
                Logger.info('Data migration completed successfully');
                resolve();
            },
            error => {
                Logger.error('Error during data migration', { error });
                resolve();
            }
        );
    });
    Logger.info('Data migration completed successfully');
};
