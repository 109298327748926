import { getHashtags } from '@nexdynamic/squeegee-common';

export const updateTagText = async (
    initialText: string,
    standardSelected: string[],
    importantSelected: string[],
    ignoreImportant: string[],
    ignoreStandard: string[]
) => {
    let textToUpdate = initialText;
    for (const hashtag of importantSelected.filter(hashtag => !textToUpdate.includes(`#!${hashtag}`))) textToUpdate += `\n#!${hashtag}`;
    for (const hashtag of standardSelected.filter(hashtag => !textToUpdate.includes(`#${hashtag}`))) textToUpdate += `\n#${hashtag}`;

    const importantInitial = Array.from(getHashtags(initialText).important.keys());
    const importantFilter = (hashtag: string) => !importantSelected.includes(hashtag) && !ignoreImportant.includes(hashtag);
    for (const initialHashtag of importantInitial.filter(importantFilter)) textToUpdate = textToUpdate.replace(`#!${initialHashtag}`, '');

    const standardInitial = Array.from(getHashtags(initialText).standard.keys());
    const standardFilter = (hashtag: string) => !standardSelected.includes(hashtag) && !ignoreStandard.includes(hashtag);
    for (const initialHashtag of standardInitial.filter(standardFilter)) {
        textToUpdate = textToUpdate.replace(`#${initialHashtag}`, '');
    }

    // Turn multiple newlines with optional whitespace into a single newline
    textToUpdate = textToUpdate.replace(/(\r?\n\s*){2,}/g, '\n\n').trim();
    return textToUpdate;
};
