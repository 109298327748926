import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { bindable } from 'aurelia-framework';
import { ApplicationState } from '../ApplicationState';

export class TableDescriptionCustomElement {
    private shortDescription = '';
    protected longDescription = '';
    protected displayedDescription = '';

    @bindable() description: TranslationKey;
    attached() {
        const localised = ApplicationState.localise(this.description);
        this.longDescription = localised;
        this.shortDescription = `${localised.substring(0, 200)}${localised.length > 200 ? '...' : ''}`;
        this.displayedDescription = this.shortDescription;
    }

    private expanded = false;

    public toggle(): void {
        this.expanded = !this.expanded;
        this.displayedDescription = this.expanded ? this.longDescription : this.shortDescription;
    }
}
