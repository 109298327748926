import { wait } from '@nexdynamic/squeegee-common';
import { Logger } from '../../Logger';
import { initialLoader } from '../../initialLoader';
import { checkLegacyTableExists } from './checkLegacyTableExists';
import { deleteLegacyTable } from './deleteLegacyTable';
import { getMigrateableDataCount } from './getMigrateableDataCount';
import { migrateDataFromLegacyTable } from './migrateDataFromLegacyTable';

export const migrateLegagyData = async (db: SQLitePlugin.Database): Promise<void> => {
    try {
        Logger.info('Upgrading database from data duplicating version');

        const legacyTableExists = await checkLegacyTableExists(db);
        if (!legacyTableExists) return;

        initialLoader('Migrating local data to new database');

        const migrateableDataCount = await getMigrateableDataCount(db);

        if (migrateableDataCount > 0) {
            initialLoader(`Found ${migrateableDataCount} items to migrate`);
            await wait(500);

            initialLoader(`Migrating ${migrateableDataCount} items, do not close the app as this may take a while...`);
            await migrateDataFromLegacyTable(db);
        }

        initialLoader('Cleaning up legacy data table');
        await await deleteLegacyTable(db);
    } catch (error) {
        Logger.error('Error during upgradeDatabaseFromDataDuplicatingVersion:', error);
    }
};
