import { Logger } from '../../Logger';
import { TABLE_NAME_LEGACY } from './TABLE_NAME_LEGACY';

export const deleteLegacyTable = async (db: SQLitePlugin.Database): Promise<void> => {
    await new Promise<void>(resolve => {
        db.executeSql(
            `DROP TABLE IF EXISTS ${TABLE_NAME_LEGACY}`,
            [],
            () => {
                Logger.info('Old table dropped successfully');
                resolve();
            },
            error => {
                Logger.error('Error dropping old table', { error });
                resolve();
            }
        );
    });
};
