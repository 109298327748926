import type {
    Audience,
    Customer,
    CustomFunction,
    ICustomerBalanceDictionary,
    Note,
    StoredObject,
    TranslationKey,
} from '@nexdynamic/squeegee-common';
import {
    AlphanumericCharColourDictionary,
    audienceFilterToCustomerSearch,
    FrequencyBuilder,
    getHashtags,
    getImportantHashtags,
    searchCustomers,
    SyncMode,
} from '@nexdynamic/squeegee-common';
import { EventAggregator } from 'aurelia-event-aggregator';
import { bindable, bindingMode, computedFrom, inject } from 'aurelia-framework';
import type { Router } from 'aurelia-router';
import { BindingSignaler } from 'aurelia-templating-resources';
import moment from 'moment';
import { ApplicationState } from '../../ApplicationState';
import type { IFabAction } from '../../Components/Fabs/IFabAction';
import { CustomUpdateFunctions } from '../../CustomUpdateFunctions';
import { Data } from '../../Data/Data';
import { AureliaReactComponentDialog } from '../../Dialogs/AureliaReactComponentDialog';
import { DialogAnimation } from '../../Dialogs/DialogAnimation';
import { Prompt } from '../../Dialogs/Prompt';
import { Select } from '../../Dialogs/Select';
import { SelectMultiple } from '../../Dialogs/SelectMultiple';
import { SendMessageToCustomer } from '../../Dialogs/SendMessageToCustomer';
import { TextDialog } from '../../Dialogs/TextDialog';
import { DataRefreshedEvent } from '../../Events/DataRefreshedEvent';
import { LoaderEvent } from '../../Events/LoaderEvent';
import type { Subscription } from '../../Events/SqueegeeEventAggregator';
import { ViewResizeEvent } from '../../Events/ViewResizeEvent';
import { FabWithActions } from '../../FabWithActions';
import { HashtagEditor } from '../../HashTags/HashtagEditor';
import type { IViewOptions } from '../../IViewOptions';
import { JobOccurrenceService } from '../../Jobs/JobOccurrenceService';
import { LocationUtilities } from '../../Location/LocationUtilities';
import { PositionUpdatedEvent } from '../../Location/PositionUpdatedEvent';
import { Logger } from '../../Logger';
import type { ISortOption } from '../../Menus/ListMenuBar';
import { NotifyUserMessage } from '../../Notifications/NotifyUserMessage';
import { SendNotificationService } from '../../Notifications/SendNotificationService';
import { Api } from '../../Server/Api';
import { RethinkDbAuthClient } from '../../Server/RethinkDbAuthClient';
import { MessageTemplates } from '../../Settings/MessageTemplates';
import { Stopwatch } from '../../Stopwatch';
import { TransactionService } from '../../Transactions/TransactionService';
import { animate, Utilities } from '../../Utilities';
import { CustomerCreditDialog } from '../Components/CustomerCreditDialog';
import { CustomerDialog, CustomerDialogTab } from '../Components/CustomerDialog';
import { CustomerFormDialog } from '../Components/CustomerFormDialog';
import type { CustomerListSortType } from '../Components/CustomerListSortType';
import { MergeCustomersDialog } from '../Components/MergeCustomersDialog';
import { SelectCustomerDialog } from '../Components/SelectCustomerDialog';
import { CustomerService } from '../CustomerService';
import { importCustomerFile } from '../methods/importCustomerFile';
import { jobOffScheduleDiffDetails } from './jobOffScheduleDiffDetails';
import type { TUpdateFunction } from './TUpdateFunction';
import type { TUpdateResult } from './TUpdateResult';
import { ViewOptionsDialog } from './ViewOptionsDialog';
@inject(EventAggregator, BindingSignaler, FabWithActions)
export class CustomerList {
    @bindable public searchText: string;
    @bindable public currentSortOption: ISortOption;

    @bindable({ defaultBindingMode: bindingMode.twoWay }) showSearchInput: boolean;

    protected sortOptions: Array<ISortOption>;
    protected customerDistances: {
        [id: string]: { value: number; text: string | undefined };
    } = {};

    protected distanceUnits = ApplicationState.distanceUnits;

    protected customerBalances: ICustomerBalanceDictionary = {};
    protected viewOptions: IViewOptions = ApplicationState.viewOptions;
    customerUninvoicedJobs: { [customerId: string]: string };
    params: { customerId?: string | undefined; tab?: CustomerDialogTab | undefined };

    @computedFrom('ApplicationState.stateFlags.showDevelopmentTools')
    protected get devMode() {
        return ApplicationState.stateFlags.devMode;
    }
    protected router: Router;
    protected isOverview: boolean;
    protected isOwingView: boolean;
    protected isCanvassingView: boolean;
    protected isOwnerAdminOrCreator = ApplicationState.isInAnyRole(['Owner', 'Admin', 'Creator']);
    protected canSearchByAudience =
        ((Data.count<Audience>('audiences', x => !!x.filterGroups?.some(fg => fg.length)) && ApplicationState.stateFlags.devMode) ||
            ApplicationState.features.send) &&
        ApplicationState.isInAnyRole(['Owner', 'Admin']);
    protected isSearching = false;
    protected canSendBulkMessage = ApplicationState.isInAnyRole(['Owner', 'Admin']);

    protected searching = (searching: boolean) => {
        this.isSearching = searching;
    };

    protected providers(customer: Customer) {
        return Object.keys(customer.externalIds || {}).filter(provider => {
            if (provider === 'gocardless_mandate') return false;
            if (provider === 'stripe' && !customer.paymentProviderMetaData?.stripe?.sourceOrMandateId) return false;
            if (provider === 'gocardless' && customer.automaticPaymentMethod !== 'gocardless') return false;
            return true;
        });
    }

    protected goCardlessStatuses = {} as Record<string, false | TranslationKey | undefined>;
    public showGoCardlessStatus(customer: Customer) {
        const status = this.goCardlessStatuses[customer._id];
        if (status !== undefined) return status;

        if (!ApplicationState.account.goCardlessPublishableKey) return (this.goCardlessStatuses[customer._id] = false);
        if (!customer?.paymentProviderMetaData?.gocardless?.status) {
            if (!customer.paymentProviderMetaData?.gocardless?.inviteNotificationId) return (this.goCardlessStatuses[customer._id] = false);

            return ApplicationState.localise('gocardless.invited');
        }

        if (customer.automaticPaymentMethod !== 'gocardless') return (this.goCardlessStatuses[customer._id] = false);

        if (customer.paymentProviderMetaData.gocardless.status === 'canceled')
            return (this.goCardlessStatuses[customer._id] = ApplicationState.localise('gocardless.cancelled'));
        if (customer.paymentProviderMetaData.gocardless.status === 'pending')
            return (this.goCardlessStatuses[customer._id] = ApplicationState.localise('gocardless.pending'));
        if (customer.paymentProviderMetaData.gocardless.status === 'active')
            return (this.goCardlessStatuses[customer._id] = ApplicationState.localise('gocardless.active'));

        return (this.goCardlessStatuses[customer._id] = false);
    }

    protected searchByAudience = async () => {
        const allowedSendAccess = ApplicationState.isInAnyRole(['Admin', 'Owner']);
        const audiences = await Data.all<Audience>('audiences', x => !!x.filterGroups?.some(fg => fg.length));
        const audienceOptions = audiences.map(audience => ({ text: audience.name, value: audience._id }));
        const audienceOptionsAndRedirectToSend =
            audienceOptions.length === 0
                ? [
                      {
                          text: ApplicationState.localise(allowedSendAccess ? 'no-audiences-create-one' : 'no-audiences-created-yet'),
                          value: allowedSendAccess ? 'direct-to-send' : '',
                      },
                  ]
                : audienceOptions;
        const selectAudience = new Select('audiences.select-an-audience', audienceOptionsAndRedirectToSend, 'text', 'value', undefined, {
            okLabel: '',
            cancelLabel: '',
            cssClass: 'audience-select',
        });
        const selected = await selectAudience.show();
        if (selectAudience.cancelled) return;
        if (selected.value === 'direct-to-send') {
            ApplicationState.navigateToRouteFragment('/send/audiences/create');
        }
        const audience = audiences.find(audience => audience._id === selected.value);
        if (!audience?.filterGroups) return;

        this.searchText = audienceFilterToCustomerSearch(audience.filterGroups);
    };

    protected stripeStatus = {} as Record<string, false | TranslationKey | undefined>;
    public showStripeStatus(customer: Customer) {
        const stripeStatus = this.stripeStatus[customer._id];
        if (stripeStatus !== undefined) return stripeStatus;

        if (!ApplicationState.account.stripePublishableKey) return (this.stripeStatus[customer._id] = false);
        if (customer.automaticPaymentMethod !== 'stripe') return (this.stripeStatus[customer._id] = false);
        if (!customer?.paymentProviderMetaData?.stripe?.status) {
            if (!customer.paymentProviderMetaData?.stripe?.inviteNotificationId) return (this.stripeStatus[customer._id] = false);

            return ApplicationState.localise('stripe.invited');
        }

        if (customer.paymentProviderMetaData.stripe.status === 'canceled')
            return (this.stripeStatus[customer._id] = ApplicationState.localise('stripe.cancelled'));
        if (customer.paymentProviderMetaData.stripe.status === 'pending')
            return (this.stripeStatus[customer._id] = ApplicationState.localise('stripe.pending'));
        if (customer.paymentProviderMetaData.stripe.status === 'active')
            return (this.stripeStatus[customer._id] = ApplicationState.localise('stripe.active'));

        return (this.stripeStatus[customer._id] = false);
    }

    public async activate(params: { customerId?: string; tab?: CustomerDialogTab }, routeConfig: any) {
        this.router = routeConfig.navModel.router;

        this.isOverview = document.location.href.indexOf('/customers') > -1;
        this.isOwingView = document.location.href.indexOf('/owing') > -1;
        this.isCanvassingView = document.location.href.indexOf('/canvassing') > -1;

        this.params = params;
    }

    private constructor(public eventAggregator: EventAggregator, public signaler: BindingSignaler, public fabWithActions: FabWithActions) {}

    private customers: Readonly<Array<Customer>>;
    private filteredCustomers: Array<Customer>;
    private _dataRefreshedSub: Subscription;
    private _routeChangedSubscription: Subscription;
    private static _colours = new AlphanumericCharColourDictionary();
    private _positionUpdatedSub: Subscription;
    private _applicationStateChanged: Subscription;

    public created() {
        new LoaderEvent(true);
    }

    private _debounceSearchTextChanged: any;
    private funcRegex = /^(?:filter\(([a-zA-Z_][\w\d_]*? => .+?)\))$/;
    private _debounceLength: number;
    public searchTextChanged(): void {
        clearTimeout(this._debounceSearchTextChanged);
        this.checkAndInitFuncs();
        this._debounceSearchTextChanged = setTimeout(() => {
            this.filter();
        }, this._debounceLength || 500);
    }

    private filterFunction?: (x: Customer) => boolean;
    protected filterFunctionValid = true;

    protected getTags(customer: Customer) {
        return getImportantHashtags(customer.notes);
    }

    protected localise = ApplicationState.localise;

    protected async sendBulkReminders() {
        const customersForReminding = this.filteredCustomers
            .map(c => {
                const balance = this.customerBalances[c._id];
                const symbol = ApplicationState.currencySymbol();
                return !balance || balance.amount < 0
                    ? undefined
                    : {
                          value: c._id,
                          text: `<strong>${c.name || 'Unknown'} ${symbol}${balance.amount.toFixed(2)} (${
                              balance.debtAgeDays
                          } days)</strong>\n${(c.address && c.address.addressDescription) || ''}`,
                      };
            })
            .filter(x => !!x) as Array<{ value: string; text: string }>;

        const customerSelect = new SelectMultiple(
            'Confirm Payment Reminders' as TranslationKey,
            customersForReminding,
            'text',
            'value',
            customersForReminding.slice(0)
        );

        customerSelect.showOrder = false;
        customerSelect.disableLocalisation = true;
        customerSelect.contextMenuBarText = 'Send' as TranslationKey;
        customerSelect.contextMenuBarAction = async () => {
            if (
                await this.messageCustomers(
                    customerSelect.selectedOptions.map(x => this.customers.find(c => c._id === x.value) as Customer).filter(c => !!c),
                    {
                        sms:
                            (ApplicationState.messagingTemplates && ApplicationState.messagingTemplates.smsPaymentRequest) ||
                            MessageTemplates.instance.smsPaymentRequest.default,
                        email:
                            (ApplicationState.messagingTemplates && ApplicationState.messagingTemplates.emailPaymentRequest) ||
                            MessageTemplates.instance.emailPaymentRequest.default,
                        emailIsHtml:
                            (ApplicationState.messagingTemplates && ApplicationState.messagingTemplates.emailPaymentRequestIsHtml) || false,
                    }
                )
            ) {
                new NotifyUserMessage('notification.sending-payment-reminders-to-count-customers', {
                    count: customerSelect.selectedOptions.length.toString(),
                });
                customerSelect.ok();
            }
        };

        await customerSelect.show();
    }

    private async messageCustomers(customers: Array<Customer>, messages?: { sms: string; email: string; emailIsHtml: boolean }) {
        if (!ApplicationState.isVerifiedForMessaging) throw new Error('Please verify your email to send messages');
        if (customers && customers.length) {
            const notifyDialog = new SendMessageToCustomer(customers, messages);
            return await notifyDialog.show();
        }
    }

    protected async sendBulkMessage() {
        if (!ApplicationState.isVerifiedForMessaging) throw new Error('Please verify your email to send messages');

        if (this.filteredCustomers === this.customers || !this.searchText) {
            if (
                !(await new Prompt('general.warning', 'notifications.bulk-send-without-filter', {
                    okLabel: 'general.yes',
                }).show())
            )
                return;
        } else {
            if (
                !(await new Prompt('general.confirm', 'notifications.bulk-send-are-you-sure', {
                    okLabel: 'general.yes',
                    localisationParams: { count: this.filteredCustomers.length.toString() },
                }).show())
            )
                return;
        }

        const dialog = new SendMessageToCustomer([...this.filteredCustomers], { sms: '', email: '', emailIsHtml: false });
        await dialog.show();
    }

    protected async bulkEditTags() {
        if (this.filteredCustomers === this.customers || !this.searchText) {
            if (
                !(await new Prompt('general.warning', 'hashtags.bulk-tag-without-filter', {
                    okLabel: 'general.yes',
                }).show())
            )
                return;
        } else {
            if (
                !(await new Prompt('general.confirm', 'hashtags.bulk-tag-are-you-sure', {
                    okLabel: 'general.yes',
                    localisationParams: { count: this.filteredCustomers.length.toString() },
                }).show())
            )
                return;
        }

        const { show, dialog } = AureliaReactComponentDialog.show({
            dialogTitle: 'hashtags.bulk-tag-title',
            component: HashtagEditor,
            isSecondaryView: true,
            componentProps: {
                items: this.filteredCustomers,
                availableHashTagMap: getHashtags(Data.all<Customer>('customers') as Array<Customer>),
            },
        });

        await show;

        if (dialog.cancelled) return;

        for (const customer of this.filteredCustomersLoaded) this.signaler.signal(customer._id);
    }

    protected async sendBulkHtmlEmail() {
        if (!ApplicationState.isVerifiedForMessaging) throw new Error('Please verify your email to send messages');

        if (this.filteredCustomers === this.customers || !this.searchText) {
            if (
                !(await new Prompt('general.warning', 'notifications.bulk-send-without-filter', {
                    okLabel: 'general.yes',
                }).show())
            )
                return;
        } else {
            if (
                !(await new Prompt('general.confirm', 'notifications.bulk-send-are-you-sure', {
                    okLabel: 'general.yes',
                    localisationParams: { count: this.filteredCustomers.length.toString() },
                }).show())
            )
                return;
        }

        const emailCustomers = this.filteredCustomers.filter(c => c.email);
        SendNotificationService.sendBulkHtmlEmail(emailCustomers);
    }

    protected async executeUpdate() {
        const customUpdateFunctions = new CustomUpdateFunctions();

        const userCreatedFunctions = await this.getAvailableUpdateFunctions();

        const availableUpdateFunctions = Object.keys(userCreatedFunctions)
            .map(x => {
                return { text: <TranslationKey>x, value: x };
            })
            .concat(
                Object.keys(customUpdateFunctions)
                    .filter(x => typeof customUpdateFunctions[x] === 'function')
                    .map(x => {
                        return { text: <TranslationKey>x, value: x };
                    })
            )
            .sort((x, y) => (x.text > y.text ? 1 : x.text < y.text ? -1 : 0));

        availableUpdateFunctions.unshift({ text: 'custom.update-all-caps', value: '' });

        const chooseUpdateFunctionDialog = new Select('general.select', availableUpdateFunctions, 'text', 'value');
        const chosenImporter = await chooseUpdateFunctionDialog.show();

        if (chooseUpdateFunctionDialog.cancelled) return;

        let updateFunction: undefined | TUpdateFunction;

        let methodBody = '';
        let isUserDefined = false;
        if (!chosenImporter.value) {
            isUserDefined = true;
        } else if (chosenImporter.value && customUpdateFunctions[chosenImporter.value]) {
            updateFunction = <TUpdateFunction>customUpdateFunctions[chosenImporter.value];
        } else if (chosenImporter.value && userCreatedFunctions[chosenImporter.value]) {
            updateFunction = userCreatedFunctions[chosenImporter.value];
            methodBody = updateFunction.toString();
            isUserDefined = true;
        }

        let newMethodBody = methodBody;
        if (
            isUserDefined &&
            (!chosenImporter.value ||
                !(await new Prompt(
                    'Edit Function' as TranslationKey,
                    'Would you like to run this update or edit/clone it first?' as TranslationKey,
                    {
                        okLabel: 'run' as TranslationKey,
                        cancelLabel: 'clone/edit' as TranslationKey,
                    }
                ).show()))
        ) {
            const result = await this.getCustomUpdateFunction(chosenImporter.value, methodBody);
            const newFunction = result && result.update;
            if (!newFunction) return;
            updateFunction = newFunction.function;
            chosenImporter.value = newFunction.name;
            newMethodBody = (result && result.newMethodBody) || '';
        }

        if (!updateFunction) return;

        if (isUserDefined && methodBody !== newMethodBody) {
            const saveUserFunctionPrompt = await new Prompt('general.save', 'save.changes-to-function-or-is-it-one-time', {
                okLabel: 'save.run',
                cancelLabel: 'general.cancel',
                altLabel: 'global.run',
            });

            const saveFunc = await saveUserFunctionPrompt.show();

            if (saveUserFunctionPrompt.cancelled) return;

            if (saveFunc) {
                await Api.put(null, '/api/custom-updates', {
                    name: chosenImporter.value,
                    methodBody: updateFunction.toString(),
                    type: 'custom-update',
                });
            }
        }

        new LoaderEvent(true);
        try {
            const update: Array<TUpdateResult> = [];
            for (const customer of this.filteredCustomers.map(c => Utilities.copyObject<Customer>(c))) {
                const updates = await updateFunction(customer, Data, ApplicationState);
                update.push(updates);
            }

            const toUpdate = update.filter(x => !!x && x !== true);

            const notUpdated = toUpdate.length.toString();

            const alreadyUpdated = update.filter(x => x === true);

            const updated = alreadyUpdated.length.toString();

            new LoaderEvent(false);
            if (alreadyUpdated && alreadyUpdated.length) {
                await new Prompt('general.success', 'custom.update-has-executed', {
                    cancelLabel: '',
                    localisationParams: { updated },
                }).show();
            }
            new LoaderEvent(true);

            if (toUpdate && toUpdate.length) {
                /*  */
                console.log('Original:', this.filteredCustomers);
                console.log('Updated:', update);
                /*  */

                new LoaderEvent(false);
                if (
                    await new Prompt('general.confirm', 'update.customers-see-console-for-changes', {
                        okLabel: 'general.confirm',
                        localisationParams: { notUpdated },
                    }).show()
                ) {
                    const store = toUpdate.reduce<Array<StoredObject>>((x: Array<StoredObject>, y: TUpdateResult) => {
                        if (y === undefined || y === true) return x;
                        if (Array.isArray(y)) {
                            for (const z of y) {
                                x.push(z);
                            }
                        } else x.push(y);
                        return x;
                    }, <Array<StoredObject>>[]);

                    new LoaderEvent(true);

                    await Data.put(store);

                    this.loadCustomers();
                }
            } else if (!alreadyUpdated.length) {
                new LoaderEvent(false);
                await new Prompt('general.success', 'no.updates-required').show();
            }
        } catch (error) {
            Logger.error('FAILED TO EXECUTE CUSTOM UPDATE', error);
            new Prompt('general.warning', 'failed.custom-update-check-console', {
                cancelLabel: '',
            }).show();
        }
        new LoaderEvent(false);
    }

    private async getAvailableUpdateFunctions(): Promise<{
        [name: string]: TUpdateFunction;
    }> {
        const updateFunctions = await Api.get<Array<CustomFunction>>(null, '/api/custom-updates');
        try {
            if (!updateFunctions || !updateFunctions.data) throw 'No data returned';

            if (!updateFunctions.data.length) return {};

            const updateFunctionDefinitions: {
                [name: string]: TUpdateFunction;
            } = {};
            for (const func of updateFunctions.data) {
                const method = /*  */ eval(func.methodBody); /*  */
                if (typeof method !== 'function') throw `${func.name} is not a valid function`;
                updateFunctionDefinitions[func.name] = method;
            }
            return updateFunctionDefinitions;
        } catch (error) {
            Logger.error('Invalid update functions', {
                updateFunctions,
                error,
            });
            return {};
        }
    }

    private async getCustomUpdateFunction(
        name?: string,
        methodBody?: string
    ): Promise<undefined | { newMethodBody: string; update: { name: string; function: TUpdateFunction } }> {
        const updateFunctionNameTextDialog = new TextDialog(
            'general.name',
            'enter.name-for-custom-function',
            name || '',
            '',
            v => (v.length > 9 ? true : 'ten.characters-required'),
            false,
            undefined,
            'general.ok'
        );

        name = await updateFunctionNameTextDialog.show();

        if (updateFunctionNameTextDialog.cancelled) return;

        methodBody =
            methodBody ||
            `
(customer, Data, ApplicationState) => {

    // Your code goes here :o)


    // Valid returns:
    // * StoredObject (usually this customer)
    //
    // * Array<StoredObject> (this customer and or other items
    //   that need saving, ensures this update is transactional
    //   and cancellable)
    //
    // * true (to indicate an update was made internally to your method)
    //
    // * undefined (no update was made or is required)

    return customer;
}
`;
        const updateFunctionTextDialog = new TextDialog(
            'general.details',
            'enter.update-function',
            methodBody,
            '',
            functionText => {
                try {
                    const f = /*  */ eval(functionText); /*  */
                    if (typeof f !== 'function') return <TranslationKey>'';
                } catch (error) {
                    Logger.info('Invalid update function', {
                        functionText,
                        error,
                    });
                    return 'invalid.update-function-see-console';
                }
                return true;
            },
            false,
            'textarea',
            'general.ok'
        );

        const functionText = await updateFunctionTextDialog.show();
        try {
            const f: TUpdateFunction = /*  */ eval(functionText); /*  */
            if (typeof f !== 'function') throw 'not a function';
            return { newMethodBody: functionText, update: { name, function: f } };
        } catch (error) {
            Logger.info('Invalid update function', { functionText, error });
        }
    }

    private checkAndInitFuncs(): any {
        const functions = this.funcRegex.exec(this.searchText);

        delete this.filterFunction;
        this.filterFunctionValid = true;
        if (functions && functions[1]) {
            const filterText = functions[1];
            try {
                this.filterFunction = /*  */ eval(filterText); /*  */
                if (typeof this.filterFunction !== 'function') {
                    delete this.filterFunction;
                    this.filterFunctionValid = false;
                }
            } catch (error) {
                this.filterFunctionValid = false;
            }
        }
    }

    public currentSortOptionChanged(newValue: ISortOption) {
        if (newValue && newValue.onSelected) newValue.onSelected();
    }
    private _delegateMergeCustomers = () => this.mergeCustomers();
    private _delegateAddCustomer = () => this.addCustomer();

    public async addCustomer(customer?: Customer) {
        try {
            const dialog = new CustomerFormDialog(customer);

            customer = await dialog.show(DialogAnimation.SLIDE_UP);

            if (!dialog.cancelled) this.viewCustomer(customer);
        } catch (error) {
            Logger.error(`Error during add customer ${customer ? customer._id : '(new)'}`, error);
        }
    }

    public async mergeCustomers(mainCustomer?: Customer, mergingCustomer?: Customer) {
        try {
            const mainCustomerDialog = new SelectCustomerDialog(undefined, 'dialogs.select-main-merge-customer-title');
            let mergingCustomerDialog;
            if (!mainCustomer && !mergingCustomer) {
                // prompt to select customers to merge
                mainCustomer = await mainCustomerDialog.show(DialogAnimation.SLIDE_UP);
                if (!mainCustomerDialog.cancelled && mainCustomer) {
                    mergingCustomerDialog = new SelectCustomerDialog(undefined, 'dialogs.select-merge-customer-title', [mainCustomer]);
                    mergingCustomer = await mergingCustomerDialog.show(DialogAnimation.SLIDE_UP);
                }
            }
            if (
                !mainCustomerDialog.cancelled &&
                mergingCustomerDialog &&
                !mergingCustomerDialog.cancelled &&
                mainCustomer &&
                mergingCustomer
            ) {
                const dialog = new MergeCustomersDialog(mainCustomer, mergingCustomer);
                const customer = await dialog.show(DialogAnimation.SLIDE_UP);

                if (!dialog.cancelled) this.viewCustomer(customer);
            }
        } catch (error) {
            Logger.error(
                `Error during merge customers - mainCustomer: ${mainCustomer ? mainCustomer._id : '(new)'}- mergingCustomer: ${
                    mergingCustomer ? mergingCustomer._id : '(new)'
                }`,
                error
            );
        }
    }
    protected totalOwing = 0;

    protected date: string = moment().format('YYYY-MM-DD');

    public async attached() {
        (window as any).view = this;

        new LoaderEvent(true);

        setTimeout(async () => {
            new ViewResizeEvent();
            if (this.isOverview || this.isCanvassingView) FabWithActions.register(this.getCustomerActions());
            this.sortOptions = this.setSortOptions();

            this.loadCustomers();

            this._dataRefreshedSub = DataRefreshedEvent.subscribe((event: DataRefreshedEvent) => {
                if (!event.hasAnyType('customers', 'transactions', 'notes')) return;
                this.customerBalances = {};
                const customerIds = [];
                for (const id in event.updatedObjects || {}) {
                    const storedObject = event.updatedObjects[id];
                    if (storedObject && storedObject.resourceType === 'customers') {
                        customerIds.push(id);
                    }
                }
                this.loadCustomers(customerIds);
            });

            this._routeChangedSubscription = this.eventAggregator.subscribe('router:navigation:success', () => {
                this.isOverview = document.location.href.indexOf('/customers') > -1;
                this.isOwingView = document.location.href.indexOf('/owing') > -1;
                this.isCanvassingView = document.location.href.indexOf('/canvassing') > -1;
                this.filter();
            });

            this._positionUpdatedSub = PositionUpdatedEvent.subscribe(async () => this.locationChanged());

            new LoaderEvent(false);

            const main = document.getElementById('main-scroll-container');
            main && main.addEventListener('scroll', this.loadMoreHandler);

            const customerId = this.params.customerId;
            if (!customerId) return;

            const customer = Data.get<Customer>(customerId);
            if (customer) this.viewCustomer(customer);
            else {
                Data.fullSync(SyncMode.Partial)
                    .catch()
                    .then(() => {
                        const customer = Data.get<Customer>(customerId);
                        if (!customer) return;

                        this.displayCustomer(customer);
                    });
            }
        }, 20);
    }
    displayCustomer(customer: Customer) {
        const tab = Number(this.params.tab) || CustomerDialogTab.CUSTOMERS;
        new CustomerDialog(customer, tab).show();
    }

    private _scrollDebounce: any;
    private loadMoreHandler = () => {
        if (this._scrollDebounce !== undefined) return;
        this._scrollDebounce = setTimeout(() => {
            if (Utilities.isElementVisible(document.getElementById('loadMoreCustomers'), 150)) {
                this.loadMore();
            }
            clearTimeout(this._scrollDebounce);
            delete this._scrollDebounce;
        }, 50);
    };

    public detached() {
        (window as any).view = {};

        const main = document.getElementById('main-scroll-container');
        main && main.removeEventListener('scroll', this.loadMoreHandler);

        this._dataRefreshedSub && this._dataRefreshedSub.dispose();
        this._routeChangedSubscription && this._routeChangedSubscription.dispose();
        this._positionUpdatedSub && this._positionUpdatedSub.dispose();
        this._applicationStateChanged && this._applicationStateChanged.dispose();
        FabWithActions.unregister();
    }

    public avatarText(customer: Customer) {
        let avatar = '';
        if (customer && customer.name.length) {
            avatar = Utilities.getAvatarTextFromName(customer.name);
        }
        return avatar;
    }

    public getAvatarColor(customer: Customer) {
        const rounds = this.getCustomerRounds(customer);
        if (rounds && rounds.length) {
            if (rounds[0].color) return rounds[0].color;
            return CustomerList._colours[rounds[0].description.substring(0, 1).toUpperCase()];
        }
    }

    protected filteredCustomersLoaded: Array<Customer> = [];

    protected loadMore() {
        this.filteredCustomersLoaded = this.filteredCustomers.slice(0, this.filteredCustomersLoaded.length + 50);
    }

    private _additionalSearchText?: { [customerId: string]: { text?: string; balance?: number } };
    private getAdditionalSearchText() {
        if (!this._additionalSearchText) {
            this._additionalSearchText = {};
            const invoiceMap = TransactionService.getInvoices().reduce<{ [customerId: string]: string }>((d, next) => {
                const text = next.invoice ? next.invoice.referenceNumber + ' #' + next.invoice.invoiceNumber : '';
                if (!d[next.customerId || '']) d[next.customerId || ''] = text;
                else d[next.customerId || ''] += text;
                return d;
            }, {});

            const notesMap = Data.all<Note>('notes').reduce<{ [customerId: string]: string }>((d, next) => {
                const text = next.content ? ` ${next.content}` : '';
                if (!d[next.relatedId || '']) d[next.relatedId || ''] = text;
                else d[next.relatedId || ''] += text;
                return d;
            }, {});

            for (const customer of this.customers) {
                const id = customer._id;
                this._additionalSearchText[id] = { text: '', balance: -this.customerBalances[id]?.amount };

                if (invoiceMap[id]) this._additionalSearchText[id].text += ` ${invoiceMap[id]}`;

                if (notesMap[id]) this._additionalSearchText[id].text += ` ${notesMap[id]}`;

                if (this.customerUninvoicedJobs[id]?.length) this._additionalSearchText[id].text += ` ${this.customerUninvoicedJobs[id]}`;

                const inactiveJobCount = Object.values(customer.jobs || {}).filter(c => c.state === 'inactive').length;
                if (inactiveJobCount) {
                    const inactiveJobsText = ` ${inactiveJobCount} inactive job${inactiveJobCount > 1 ? 's' : ''}`;
                    this._additionalSearchText[id].text += inactiveJobsText;
                }

                for (const job of Object.values(customer.jobs || {})) {
                    if (job.location?.addressDescription && job.location?.addressDescription === customer.address?.addressDescription) {
                        this._additionalSearchText[id].text += ` ${job.location?.addressDescription}`;
                    }

                    let frequencyText = '';
                    if (job.frequencyInterval !== undefined && job.frequencyType !== undefined) {
                        const frequency = FrequencyBuilder.getFrequency(
                            job.frequencyInterval,
                            job.frequencyType,
                            job.date || moment(),
                            job.frequencyDayOfMonth,
                            job.frequencyWeekOfMonth,
                            Array.isArray(job.frequencyDayOfWeek) ? job.frequencyDayOfWeek[0] : job.frequencyDayOfWeek
                        );
                        frequencyText = ApplicationState.localise(
                            frequency.localisationKeyAndParams.key,
                            frequency.localisationKeyAndParams.params
                        );

                        this._additionalSearchText[id].text += ` ${frequencyText}`;
                    }
                }
            }
        }
        return this._additionalSearchText;
    }
    public async filter() {
        if (!this.filteredCustomers) this.filteredCustomers = [];

        if (!this.customers) return;

        if (!this.isOverview && !this.isOwingView && !this.isCanvassingView) return;

        const timer = new Stopwatch('customer filter');
        const isOwnerAdminOrCreator = ApplicationState.isInAnyRole(['Owner', 'Admin', 'Creator']);

        const user = RethinkDbAuthClient.session && RethinkDbAuthClient.session.email;
        const isAdminOrOwner = ApplicationState.isInAnyRole(['Admin', 'Owner', 'Creator']);

        const optionsFilter = this.isCanvassingView
            ? (customer: Customer) => customer.state === 'prospect' && (isAdminOrOwner || customer.updatedByUser === user)
            : (customer: Customer) => this._isOptionsMatch(customer, isOwnerAdminOrCreator);

        const mobilePrefixes = ApplicationState.account.smsMobilePhonePrefixes?.split(',').map(x => x.trim());
        let newFilteredCustomers: Array<Customer>;

        for (const customer of this.customers) this.getBalance(customer._id);
        if (this.filterFunction) {
            newFilteredCustomers = this.customers.filter(optionsFilter).filter(this.filterFunction);
        } else {
            if (this.searchText) {
                const additional = this.getAdditionalSearchText();
                const search = () =>
                    searchCustomers({
                        customers: this.customers,
                        searchText: this.searchText,
                        searchJobs: true,
                        additional,
                        mobilePrefixes,
                    });
                newFilteredCustomers = search().filter(optionsFilter);
            } else {
                newFilteredCustomers = this.customers.filter(optionsFilter);
            }
        }

        newFilteredCustomers = this.filterToOnlyOffScheduleCustomers(newFilteredCustomers).filter(optionsFilter);

        timer.lap('filter complete');

        if (this.currentSortOption && this.currentSortOption.sorter) {
            newFilteredCustomers = newFilteredCustomers.sort(this.currentSortOption.sorter);
        }
        timer.lap('sort complete');

        this.filteredCustomers = newFilteredCustomers;

        this.totalOwing = 0;
        for (const customer of this.filteredCustomers) {
            this.totalOwing += this.customerBalances[customer._id].amount || 0;
        }

        this.filteredCustomersLoaded = this.filteredCustomers.slice(0, 50);

        new ViewResizeEvent();
    }

    protected getCustomerBalance = (customerId: string) => {
        const balance = this.customerBalances[customerId];
        if (!balance) return 0;

        if (this.isOwingView && this.viewOptions.onlyOverdueUnpaidInvoices) return balance.overdue;

        return balance.amount;
    };

    private customersOffScheduleInfo: Record<string, string> = {};
    private filterToOnlyOffScheduleCustomers(customers: Array<Customer>) {
        this.customersOffScheduleInfo = {};

        if (!this.viewOptions.onlyShowOffSchedule) return customers;

        const today = moment().format('YYYY-MM-DD');

        const newFilteredCustomers = new Array<Customer>();

        for (const customer of customers) {
            const jobs = Object.values(customer.jobs || {});
            for (const job of jobs) {
                const diffDetails = jobOffScheduleDiffDetails(job, today);
                if (!diffDetails) continue;

                this.customersOffScheduleInfo[customer._id] = diffDetails;
                newFilteredCustomers.push(customer);
            }
        }

        return newFilteredCustomers;
    }

    private _isOptionsMatch(customer: Customer, isOwnerAdminOrCreator: boolean) {
        if (
            this.isOwingView &&
            !isOwnerAdminOrCreator &&
            (customer.hideJobPricesFromWorker === true ||
                (customer.hideJobPricesFromWorker === undefined && ApplicationState.account.hidePricesFromWorkerRole))
        ) {
            return false;
        }

        // Hide active, inactive and prospect if their respective filters are set.
        if (!this.viewOptions.showActiveCustomers && (!customer.state || customer.state === 'active')) return false;
        if (!this.viewOptions.showInactiveCustomers && customer.state === 'inactive') return false;
        if (!this.viewOptions.showProspectiveCustomers && customer.state === 'prospect') return false;

        // Owing view and this customer owes nothing.
        const balance = this.getBalance(customer._id);
        const balanceAmount = balance.amount || 0;
        const overdueBalance = balance.overdue || 0;

        if (this.isOwingView && balanceAmount <= 0) return false;

        if (this.isOwingView && this.viewOptions.onlyOverdueUnpaidInvoices) {
            if (overdueBalance <= 0) return false;
        }

        return true;
    }
    protected paymentTitle(customer: Customer) {
        return this.localise('tooltips.payment-title', {
            lastPaid: (customer.lastPaymentMethod || '')
                .replace(/.*?\./, '')
                .replace(/-/g, ' ')
                .replace(/\b([a-z])/g, function (_, initial) {
                    return initial.toUpperCase();
                }),
        });
    }
    private debounceLoadCustomers: any;
    private loadCustomers(updated?: Array<string>) {
        try {
            clearTimeout(this.debounceLoadCustomers);
            this.debounceLoadCustomers = setTimeout(
                async () => {
                    const customers = CustomerService.getCustomers() || [];
                    this._debounceLength = customers.length / 8;
                    if (this._debounceLength < 150) this._debounceLength = 150;
                    else if (this._debounceLength > 500) this._debounceLength = 500;

                    this.updateCustomerDistances(customers);

                    this.customerUninvoicedJobs = {} as { [customerId: string]: string };
                    let count = 0;
                    for (const customer of customers) {
                        count++;
                        if (count > 0 && count % 500 === 0) await animate();
                        const uninvoicedCount = JobOccurrenceService.countUninvoicedJobOccurrences(customer);
                        if (uninvoicedCount)
                            this.customerUninvoicedJobs[customer._id] = `${uninvoicedCount} uninvoiced job${
                                uninvoicedCount > 1 ? 's' : ''
                            }`;
                    }

                    this.customers = customers;

                    delete this._additionalSearchText;
                    await this.filter();
                    this._customerRounds = {};

                    for (const id of updated || []) {
                        this.signaler.signal(id);
                    }

                    delete this.debounceLoadCustomers;
                },
                this.debounceLoadCustomers ? 250 : 0
            );
        } catch (error) {
            Logger.error(
                `Error during load customer in the customer list(${
                    this.isOverview ? 'overview' : this.isOwingView ? 'owing' : 'unknown view'
                })`,
                error
            );
        }
    }

    protected customerBalanceLabels = {} as { [id: string]: string };
    private getBalance(customerId: string) {
        if (!this.customerBalances[customerId]) {
            const balance = TransactionService.getCustomerBalance(customerId, this.date);
            this.customerBalances[customerId] = balance;
            this.customerBalanceLabels[customerId] = !balance
                ? 'financials.balance-label'
                : balance.amount < 0
                ? 'financials.credit-label'
                : balance.debtAgeDays
                ? ApplicationState.localise('financial.owed-days', {
                      days: balance.debtAgeDays.toString(),
                  })
                : ApplicationState.localise('financial.owed');
        }
        return this.customerBalances[customerId];
    }

    private _customerRounds: { [customerId: string]: Array<{ description: string; color: string }> } = {};
    protected getCustomerRounds(customer: Customer) {
        if (this._customerRounds[customer._id]) return this._customerRounds[customer._id];

        if (!customer.jobs) return '';

        const rounds: Array<{ description: string; color: string }> = [];

        for (const jobId of Object.keys(customer.jobs)) {
            for (const round of customer.jobs[jobId].rounds) {
                const defaultColor =
                    CustomerList._colours[round.description.substring(0, 1).toUpperCase()] || CustomerList._colours.default;
                if (
                    round &&
                    round.description &&
                    !rounds.find(r => {
                        return r.description === round.description;
                    })
                )
                    rounds.push({ description: round.description, color: round.color || defaultColor });
            }
        }
        this._customerRounds[customer._id] = rounds; //Utilities.joinStringsNeatly(rounds);
        return this._customerRounds[customer._id];
    }

    public async viewCustomer(customer: Customer) {
        try {
            const dialog = new CustomerDialog(customer);
            await dialog.show(DialogAnimation.SLIDE_UP);
        } catch (error) {
            Logger.error(`Error during view customer ${customer._id} in the customer list`, error);
        }
    }

    public async viewCustomerOrPayment(customer: Customer) {
        try {
            if (this.isOwingView) {
                const dialog = CustomerCreditDialog.createForCustomer(customer);
                await dialog.show(DialogAnimation.SLIDE_UP);
            } else {
                await this.viewCustomer(customer);
            }
        } catch (error) {
            Logger.error(`Error during viewCustomerOrPayment for customer ${customer._id} in the customer list`, error);
        }
    }

    private getCustomerActions(): Array<IFabAction> {
        const fabActions: Array<IFabAction> = [
            {
                tooltip: 'actions.add-new-customer',
                actionType: 'action-new-customer',
                handler: this._delegateAddCustomer,
                roles: ['Owner', 'Admin', 'Creator', 'Canvasser'],
            },
            {
                tooltip: 'actions.merge-customers',
                actionType: 'action-merge-customers',
                handler: this._delegateMergeCustomers,
                roles: ['Owner', 'Admin'],
            },
            {
                tooltip: 'deleted-customer.restore-action',
                actionType: 'action-payment-refresh',
                handler: this.restoreDeletedCustomers,
                roles: ['Owner', 'Admin'],
            },
        ];

        if (ApplicationState.stateFlags.devMode) {
            fabActions.push({
                tooltip: 'Import customer from file (DEV)' as TranslationKey,
                actionType: 'action-import',
                handler: () => {
                    importCustomerFile();
                },
                roles: ['Owner', 'Admin'],
            });
        }

        return fabActions;
    }

    private setSortOptions() {
        const options: Array<ISortOption> = [
            {
                name: 'Alphabetical',
                title: 'sorting.by-alphabetical',
                onSelected: this.saveSortOption,
                sorter: this.sortCustomerName,
            },
            {
                name: 'Owing',
                title: 'sorting.by-owing',
                onSelected: this.saveSortOption,
                sorter: this.sortCustomersOwing,
            },
            {
                name: 'Debt Age',
                title: 'Debt Age Oldest' as TranslationKey,
                onSelected: this.saveSortOption,
                sorter: this.sortCustomersDebtAge,
            },
            {
                name: 'Debt Age Newest',
                title: 'Debt Age Newest' as TranslationKey,
                onSelected: this.saveSortOption,
                sorter: this.sortCustomersDebtAgeNewest,
            },
            {
                name: 'In Credit',
                title: 'sorting.by-credit',
                onSelected: this.saveSortOption,
                sorter: this.sortCustomersCredit,
            },
            {
                name: 'Distance',
                title: 'sorting.by-distance',
                onSelected: this.saveSortOption,
                sorter: this.sortCustomerDistance,
            },
            {
                name: 'Round',
                title: 'sorting.by-round',
                onSelected: this.saveSortOption,
                sorter: this.sortRound,
            },
        ];
        this.currentSortOption = this._getSavedOrDefaultSortOption(options);
        return options;
    }

    private _getSavedOrDefaultSortOption(options: Array<ISortOption>) {
        const savedSortName = this.isOwingView
            ? ApplicationState.viewOptions.customerListSortOrderOwing
            : ApplicationState.viewOptions.customerListSortOrder;
        if (savedSortName) {
            const matchingOptions = options.filter(sortOption => {
                return sortOption.name === savedSortName;
            });
            if (matchingOptions) return matchingOptions[0];
        }
        const option = options[this.isOwingView ? 2 : 0];
        return option || options[0];
    }

    saveSortOption = () => {
        this.filter();
        if (this.currentSortOption && this.currentSortOption.name) {
            if (this.isOwingView) {
                ApplicationState.viewOptions.customerListSortOrderOwing = <CustomerListSortType>this.currentSortOption.name;
                ApplicationState.saveViewOptions();
            } else if (this.isCanvassingView) {
                ApplicationState.viewOptions.customerListSortOrderCanvassing = <CustomerListSortType>this.currentSortOption.name;
                ApplicationState.saveViewOptions();
            } else if (this.isOverview) {
                ApplicationState.viewOptions.customerListSortOrder = <CustomerListSortType>this.currentSortOption.name;
                ApplicationState.saveViewOptions();
            }
        }
    };

    private locationChanged() {
        this.updateCustomerDistances(this.customers || []);
        if (this.currentSortOption.name === 'Distance') this.filter();
    }

    private async updateCustomerDistances(customers: Readonly<Array<Customer>>) {
        if (!ApplicationState.position) return;

        this.customerDistances = {};
        for (let i = 0; i < customers.length; i++) {
            if (i > 0 && i % 500 === 0) await animate(25);
            const customer = customers[i];
            if (customer && customer.address && customer.address.lngLat) {
                const distance = LocationUtilities.getDistance(customer.address.lngLat);
                const distanceText = distance
                    ? distance.toFixed(distance < 5 ? 2 : 0) + ApplicationState.distanceUnitsAbbreviated
                    : undefined;
                this.customerDistances[customer._id] = {
                    value: distance ? distance : 999,
                    text: distanceText ? distanceText : undefined,
                };
            }
        }
    }

    protected sortCustomerDistance = (customerA: Customer, customerB: Customer): number => {
        const customerADistance = this.customerDistances[customerA._id];
        const customerBDistance = this.customerDistances[customerB._id];
        if (!customerADistance || !customerBDistance || !customerADistance.value || !customerBDistance.value) {
            if (!customerADistance || !customerADistance.value) {
                return -1;
            } else return 1;
        } else {
            return customerADistance.value - customerBDistance.value;
        }
    };

    protected sortRound = (customerA: Customer, customerB: Customer): number => {
        const getFirstRoundDescription = (customer: Customer): string => {
            if (!customer.jobs) return 'ZZZ';
            const jobKeys = Object.keys(customer.jobs || {});
            if (!jobKeys.length) return 'ZZZ';

            const firstJob = customer.jobs[jobKeys[0]];
            if (!firstJob?.rounds?.length) return 'ZZZ';

            return firstJob.rounds[0]?.description || 'ZZZ';
        };

        const roundA = getFirstRoundDescription(customerA);
        const roundB = getFirstRoundDescription(customerB);

        return roundA.localeCompare(roundB);
    };

    protected sortCustomersOwing = (customerA: Customer, customerB: Customer): number => {
        return this.sortCustomersBalance(customerA, customerB, true);
    };

    protected sortCustomersDebtAge = (customer1: Customer, customer2: Customer): number => {
        const age1 = (this.customerBalances[customer1._id] && this.customerBalances[customer1._id].debtAgeDays) || 0;
        const age2 = (this.customerBalances[customer2._id] && this.customerBalances[customer2._id].debtAgeDays) || 0;
        return age1 < age2 ? 1 : age1 > age2 ? -1 : 0;
    };

    protected sortCustomersDebtAgeNewest = (customer1: Customer, customer2: Customer): number => {
        const age1 = (this.customerBalances[customer1._id] && this.customerBalances[customer1._id].debtAgeDays) || 0;
        const age2 = (this.customerBalances[customer2._id] && this.customerBalances[customer2._id].debtAgeDays) || 0;
        return age1 < age2 ? -1 : age1 > age2 ? 1 : 0;
    };

    protected sortCustomersCredit = (customerA: Customer, customerB: Customer): number => {
        return this.sortCustomersBalance(customerA, customerB, false);
    };

    protected sortCustomersBalance(customerA: Customer, customerB: Customer, sortOwing = true): number {
        const customerAOwing = (this.customerBalances[customerA._id] && this.customerBalances[customerA._id].amount) || 0;
        const customerBOwing = (this.customerBalances[customerB._id] && this.customerBalances[customerB._id].amount) || 0;
        return sortOwing ? customerBOwing - customerAOwing : customerAOwing - customerBOwing;
    }

    protected sortCustomerName = (customerA: Customer, customerB: Customer) => {
        const customerAName = Utilities.stripTitlesAndTrimName(customerA.name);
        const customerBName = Utilities.stripTitlesAndTrimName(customerB.name);
        return customerAName > customerBName ? 1 : customerAName < customerBName ? -1 : 0;
    };

    protected showViewOptions = async () => {
        const viewOptions = Utilities.copyObject(this.viewOptions);
        const viewOptionDialog = new ViewOptionsDialog(viewOptions);
        const viewOptionsResult = await viewOptionDialog.show();
        if (viewOptionsResult) {
            this.viewOptions.showActiveCustomers = viewOptionsResult.showActiveCustomers;
            this.viewOptions.showInactiveCustomers = viewOptionsResult.showInactiveCustomers;
            this.viewOptions.showProspectiveCustomers = viewOptionsResult.showProspectiveCustomers;
            this.viewOptions.onlyOverdueUnpaidInvoices = viewOptionsResult.onlyOverdueUnpaidInvoices;

            if (viewOptionsResult.onlyShowOffSchedule) await this.setOffScheduleTolerance();
            this.viewOptions.onlyShowOffSchedule = viewOptionsResult.onlyShowOffSchedule;

            ApplicationState.saveViewOptions();
            this.customerBalances = {};
            this.customerBalanceLabels = {};
            await this.filter();
            this.signaler.signal('balances-refreshed');
        }
    };

    protected offScheduleToleranceLateDescription = ApplicationState.viewOptions.offScheduleToleranceLate + '%';
    protected offScheduleToleranceEarlyDescription = ApplicationState.viewOptions.offScheduleToleranceEarly + '%';
    private async setOffScheduleTolerance() {
        const offScheduleToleranceLate =
            Number(
                await new TextDialog(
                    'view-options.off-schedule-tolerance-late-percent-title',
                    'view-options.off-schedule-tolerance-late-percent-description',
                    (this.viewOptions.offScheduleToleranceLate || 100).toString(),
                    '',
                    v => {
                        const num = parseInt(v);
                        return (
                            (num.toString() === v && num >= 10 && num <= 200) || 'view-options.off-schedule-tolerance-late-percent-invalid'
                        );
                    },
                    false,
                    'number'
                ).show()
            ) || 100;
        this.viewOptions.offScheduleToleranceLate = offScheduleToleranceLate;
        this.offScheduleToleranceLateDescription = offScheduleToleranceLate + '%';

        const offScheduleToleranceEarly =
            Number(
                await new TextDialog(
                    'view-options.off-schedule-tolerance-early-percent-title',
                    'view-options.off-schedule-tolerance-early-percent-description',
                    (this.viewOptions.offScheduleToleranceEarly || 25).toString(),
                    '',
                    v => {
                        const num = parseInt(v);
                        return (
                            (num.toString() === v && num >= 10 && num <= 90) || 'view-options.off-schedule-tolerance-early-percent-invalid'
                        );
                    },
                    false,
                    'number'
                ).show()
            ) || 25;
        this.viewOptions.offScheduleToleranceEarly = offScheduleToleranceEarly;
        this.offScheduleToleranceEarlyDescription = offScheduleToleranceEarly + '%';
    }

    public restoreDeletedCustomers = async (searchText?: string): Promise<void> => {
        CustomerService.restoreDeletedCustomers(searchText);
    };
}
