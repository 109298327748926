import type { CSVImporterTemplate, CSVTemplateOutput } from '@nexdynamic/nex-ui-react';
import type { CSVMappedColumn } from '@nexdynamic/nex-ui-react/src/importer/types/CSVMappedColumn';
import { PaymentDetails, Transaction, TransactionType } from '@nexdynamic/squeegee-common';
import { formatISO, parse } from 'date-fns';
import moment from 'moment';
import { useState } from 'react';
import { z } from 'zod';
import { ApplicationState } from '../ApplicationState';
import { Data } from '../Data/Data';
import { getUserDateFormat } from './utils/getUserDateFormat';

const amountSchema = z.coerce.number().transform(value => {
    if (typeof value === 'string') {
        return parseFloat(parseFloat(value).toFixed(2));
    }
    return parseFloat(value.toFixed(2));
});

export const TransactionCSVTemplateSchema = {
    columns: [
        {
            field: 'in',
            name: 'Amount In',
            required: (cols: CSVMappedColumn[]) => {
                return !cols.some(col => col.destinationColumn?.field === 'in' || col.destinationColumn?.field === 'out');
            },
            description: 'Payment from debtor',
            suggestedMappings: ['In', 'Payment'],
            exampleValue: '12.45',
            schema: amountSchema,
        },
        {
            field: 'out',
            name: 'Amount Out',
            required: (cols: CSVMappedColumn[]) => {
                return !cols.some(col => col.destinationColumn?.field === 'in' || col.destinationColumn?.field === 'out');
            },
            description: 'Expense payment to creditor',
            suggestedMappings: ['Expense', 'Out'],
            exampleValue: '34.99',
            schema: amountSchema,
        },
        {
            field: 'date',
            name: 'Date',
            required: true,
            description: 'The date of the transaction',
            suggestedMappings: ['Date'],
            exampleValue: '2021-01-01',
            schema: z.coerce.date(),
            formatOptions: [
                {
                    label: 'YYYY-MM-DD',
                    value: 'yyyy-MM-dd',
                    format: (date: string) => formatISO(parse(date, 'yyyy-MM-dd', new Date())),
                },
                {
                    label: 'MM/DD/YYYY',
                    value: 'MM/dd/yyyy',
                    format: (date: string) => formatISO(parse(date, 'MM/dd/yyyy', new Date())),
                },
                {
                    label: 'DD/MM/YYYY',
                    value: 'dd/MM/yyyy',
                    format: (date: string) => formatISO(parse(date, 'dd/MM/yyyy', new Date())),
                },
                {
                    label: 'MM/DD/YY',
                    value: 'MM/dd/yy',
                    format: (date: string) => formatISO(parse(date, 'MM/dd/yy', new Date())),
                },
                {
                    label: 'DD/MM/YY',
                    value: 'dd/MM/yy',
                    format: (date: string) => formatISO(parse(date, 'dd/MM/yy', new Date())),
                },
            ],
            defaultFormatValue: getUserDateFormat(),
        },
        {
            field: 'description',
            name: 'Description',
            description: 'The description of the transaction',
            suggestedMappings: ['Description'],
            exampleValue: 'Payment from John Smith',
            schema: z.string().optional(),
        },
        {
            field: 'customerRef',
            name: 'Customer Reference',
            description: 'The customer reference of the transaction',
            suggestedMappings: ['Customer Reference'],
            exampleValue: 'JDSMITH',
            schema: z.string().optional(),
        },
        {
            field: 'paymentReference',
            name: 'Payment Reference',
            description: 'The payment reference of the transaction',
            suggestedMappings: ['Payment Reference'],
            exampleValue: 'INV-123',
            schema: z.string().optional(),
        },
    ],
    schemaName: 'Squeegee Transaction',
} satisfies CSVImporterTemplate;

const isUnreconciledDebitOrCredit = (row: CSVTemplateOutput<typeof TransactionCSVTemplateSchema>) => {
    return row.in ? 'unreconciled.credit' : 'unreconciled.debit';
};

export const useImportTransactions = () => {
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const doImport = async (paymentAccount: string, data: CSVTemplateOutput<typeof TransactionCSVTemplateSchema>[]) => {
        setLoading(true);
        if (data) {
            try {
                const transactions: Transaction[] = [];
                for (const row of data) {
                    const amount = (row.in || row.out) as number;

                    const isPositiveValue = row.in ? true : false;

                    const transaction = new Transaction(
                        TransactionType.Unreconciled,
                        isUnreconciledDebitOrCredit(row),
                        isPositiveValue ? -amount : amount, // Invert the amount if it's a credit
                        undefined,
                        undefined,
                        row.description as string,
                        row.date as string,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        paymentAccount
                    );
                    transaction.transactionType = TransactionType.Unreconciled;
                    transaction.status = 'unreconciled';
                    transaction.accountId = paymentAccount;
                    transaction.paymentDetails = new PaymentDetails('csv-import');
                    transaction.paymentDetails.reference = (row.paymentReference as string) || '';
                    transaction.paymentDetails.paymentProviderData = {
                        sourceOrMandateId: ApplicationState.localise('importer.match-payment-source', {
                            date: moment().format('LL'),
                            user: ApplicationState.userAuthorisation.userEmail,
                        }),
                    };
                    transaction.paymentDetails.paymentAccountId = paymentAccount;
                    transaction.providerId = 'csv-import';
                    transaction.provider = 'csv-import';
                    transactions.push(transaction);
                }

                await Data.put(transactions);
            } catch (error) {
                setError('Failed to import transactions');
            }
        }
        setLoading(false);
    };

    return {
        doImport,
        error,
        loading,
    };
};
