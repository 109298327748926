import type { JobSummary } from '../Jobs/JobSummary';

export class JobSummaryFilterValueConverter {
    toView(array: Array<JobSummary>, searchTextOriginal?: string) {
        if (!searchTextOriginal) return array;

        const searchText = searchTextOriginal.toLowerCase();
        return JobSummaryFilterValueConverter.searchJobSummaries(searchText, array);
    }

    private static isMatch(propertyValue: any, searchText: string) {
        const value = (propertyValue || '').toString().toLowerCase();
        return value.indexOf(searchText) > -1;
    }

    public static searchJobSummaries(searchText: string, jobSummaries: Array<JobSummary>) {
        return jobSummaries.filter(jobSummary => {
            if (JobSummaryFilterValueConverter.isMatch(jobSummary.job._externalId || '', searchText)) return true;
            if (JobSummaryFilterValueConverter.isMatch(jobSummary.job.location?.addressDescription || '', searchText)) return true;
            if (JobSummaryFilterValueConverter.isMatch(jobSummary.job.description, searchText)) return true;
            if (JobSummaryFilterValueConverter.isMatch(jobSummary.roundsDescription, searchText)) return true;
            if (JobSummaryFilterValueConverter.isMatch(jobSummary.servicesDescription, searchText)) return true;
            if (JobSummaryFilterValueConverter.isMatch(jobSummary.job.price || 0, searchText)) return true;
            return false;
        });
    }
}
