import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { FileUploadDialog } from '../../Dialogs/FileUploadDialog';
import { Api } from '../../Server/Api';

export const importCustomerFile = async () => {
    const dialog = new FileUploadDialog(['.json'], 'Import Customer JSON File' as TranslationKey);

    const files = await dialog.show();

    if (!files || files.length === 0) {
        console.log('No file selected.');
        return;
    }

    const response = await Api.postFile(null, `/api/customer/import`, files[0]);
    console.log(response?.data);
    return response;
};
