import { bindable } from "aurelia-framework";
import { ApplicationState } from "../../ApplicationState";
import "./pagination.scss";

type PaginationItem = {
	onClick: (event: Event) => void;
	type: string;
	page: number | null;
	selected: boolean;
	disabled: boolean;
	"aria-current"?: string;
};

export class PaginationCustomElement {
	// Bindable properties
	@bindable public count = 1;
	@bindable public defaultPage = 1;
	@bindable public page: number | undefined;
	@bindable public boundaryCount = 1;
	@bindable public disabled = false;
	@bindable public hideNextButton: boolean;
	@bindable public hidePrevButton: boolean;
	@bindable public showFirstButton: boolean;
	@bindable public showLastButton: boolean;
	@bindable public siblingCount = 1;
	@bindable public onChange: (page: number) => void;

	protected darkMode = ApplicationState.getSetting<boolean>(
		"experiments.dark-mode",
	);

	// Internal state for the page if the component is uncontrolled.
	private _internalPage: number = this.defaultPage;
	public items: PaginationItem[] = [];

	// Returns the current page: if a controlled value is provided, use it; otherwise, use the internal state.
	get currentPage(): number {
		return this.page !== undefined && this.page !== null
			? this.page
			: this._internalPage;
	}

	// Sets the current page only if the component is uncontrolled.
	set currentPage(newPage: number) {
		if (this.page === undefined || this.page === null) {
			this._internalPage = newPage;
		}
	}

	attached(): void {
		this.updateItems();
	}

	propertyChanged(): void {
		this.updateItems();
	}

	range(start: number, end: number): number[] {
		const length = end - start + 1;
		return Array.from({ length }, (_, i) => start + i);
	}

	updateItems(): void {
		const {
			count,
			boundaryCount,
			siblingCount,
			disabled,
			hidePrevButton,
			hideNextButton,
			showFirstButton,
			showLastButton,
		} = this;
		const currentPage = this.currentPage;

		// Compute the start and end pages of the fixed boundaries.
		const startPages = this.range(1, Math.min(boundaryCount, count));
		const endPages = this.range(
			Math.max(count - boundaryCount + 1, boundaryCount + 1),
			count,
		);

		// Compute the sibling boundaries.
		const siblingsStart = Math.max(
			Math.min(
				currentPage - siblingCount,
				count - boundaryCount - siblingCount * 2 - 1,
			),
			boundaryCount + 2,
		);
		const siblingsEnd = Math.min(
			Math.max(
				currentPage + siblingCount,
				boundaryCount + siblingCount * 2 + 2,
			),
			count - boundaryCount - 1,
		);

		// Build a basic list of items.
		const itemList: (number | string)[] = [
			...(showFirstButton ? ["first"] : []),
			...(hidePrevButton ? [] : ["previous"]),
			...startPages,
			// Start ellipsis or adjacent page.
			...(siblingsStart > boundaryCount + 2
				? ["start-ellipsis"]
				: boundaryCount + 1 < count - boundaryCount
					? [boundaryCount + 1]
					: []),
			// Sibling pages.
			...this.range(siblingsStart, siblingsEnd),
			// End ellipsis or adjacent page.
			...(siblingsEnd < count - boundaryCount - 1
				? ["end-ellipsis"]
				: count - boundaryCount > boundaryCount
					? [count - boundaryCount]
					: []),
			...endPages,
			...(hideNextButton ? [] : ["next"]),
			...(showLastButton ? ["last"] : []),
		];

		// Helper: maps a button type to its corresponding page number.
		const buttonPage = (type: string): number => {
			switch (type) {
				case "first":
					return 1;
				case "previous":
					return currentPage - 1;
				case "next":
					return currentPage + 1;
				case "last":
					return count;
				default:
					return 0;
			}
		};

		// Convert the itemList to an array of PaginationItem objects.
		this.items = itemList.map((item): PaginationItem => {
			if (typeof item === "number") {
				return {
					onClick: (event: Event): void => {
						this.handleClick(event, item);
					},
					type: "page",
					page: item,
					selected: item === currentPage,
					disabled: disabled,
					"aria-current": item === currentPage ? "page" : undefined,
				};
			} else {
				return {
					onClick: (event: Event): void => {
						const newPage = buttonPage(item);
						this.handleClick(event, newPage);
					},
					type: item,
					page: buttonPage(item),
					selected: false,
					disabled:
						disabled ||
						(!item.includes("ellipsis") &&
							(item === "next" || item === "last"
								? currentPage >= count
								: currentPage <= 1)),
				};
			}
		});
	}

	handleClick(_event: Event, value: number): void {
		if (this.page === undefined || this.page === null) {
			this.currentPage = value;
		}
		if (this.onChange) {
			this.onChange(value);
		}
		this.updateItems();
	}
}
