import { Logger } from '../../Logger';
import { TABLE_NAME_LEGACY } from './TABLE_NAME_LEGACY';

export const getMigrateableDataCount = async (db: SQLitePlugin.Database): Promise<number> => {
    const migrateableDataCount = await new Promise<number>(resolve => {
        db.executeSql(
            `SELECT COUNT(*) AS count FROM ${TABLE_NAME_LEGACY} WHERE jsonData IS NOT NULL`,
            [],
            result => {
                resolve(result.rows.item(0).count);
            },
            error => {
                Logger.error('Error, unable to count migrateable data', { error });
                resolve(0);
            }
        );
    });
    Logger.info(`Found ${migrateableDataCount} records to migrate`);
    return migrateableDataCount;
};
