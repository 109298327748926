import type { TranslationKey } from '../../../../squeegee-common/src';
import { Prompt } from '../../Dialogs/Prompt';
import { Api } from '../../Server/Api';

export const downloadCustomer = async (customerId: string) => {
    const result = await new Prompt(
        'Download customer' as TranslationKey,
        'This will download the customer details, notifications and transactions. it will not include attachments or campaigns sent to this customer. Are you sure you want to continue?' as TranslationKey
    ).show();

    if (result) {
        const response = await Api.downloadFile(null, `/api/customer/${customerId}/download`, `customer-${customerId}.json`);
        return response;
    }
};
