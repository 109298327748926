import { Box, Paper, Tab, Tabs } from '@mui/material';
import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { getHashtags } from '@nexdynamic/squeegee-common';
import { useEffect, useState } from 'react';
import type {
    AureliaReactComponentDialogComponent,
    AureliaReactComponentDialogComponentStandardProps,
} from '../Dialogs/AureliaReactComponentDialogComponent';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import useTranslation from '../ReactUI/hooks/useTranslation';
import type { HashTagEditorProps } from './HashTagEditorProps';
import { HashtagAutoComplete } from './HashtagAutoComplete';
import { updateTags } from './updateTags';

type Props = AureliaReactComponentDialogComponentStandardProps<HashTagEditorProps> & HashTagEditorProps;

enum TabIndex {
    Important,
    Standard,
}

export const HashtagEditor: AureliaReactComponentDialogComponent<HashTagEditorProps> = ({
    items,
    availableHashTagMap,
    saveButton,
    okCallback,
}: Props) => {
    const [tab, setTab] = useState<TabIndex>(TabIndex.Important);

    const [importantValue, setImportantValue] = useState('');
    const [importantIgnore, setImportantIgnore] = useState(new Array<string>());
    const [importantSelected, setImportantSelected] = useState(new Array<string>());
    const [importantAvailable, setImportantAvailable] = useState(new Array<string>());

    const [standardValue, setStandardValue] = useState('');
    const [standardIgnore, setStandardIgnore] = useState(new Array<string>());
    const [standardSelected, setStandardSelected] = useState(new Array<string>());
    const [standardAvailable, setStandardAvailable] = useState(new Array<string>());

    const [inProgress, setInProgress] = useState('');

    const { t } = useTranslation();

    if (!items.length) throw new Error('No items provided');

    useEffect(() => {
        const hashtagMap = getHashtags(items);

        const important = Array.from(hashtagMap.important);
        setImportantIgnore(important.filter(x => x[1] < items.length).map(x => x[0]));
        setImportantSelected(important.filter(x => x[1] === items.length).map(x => x[0]));

        const availableImportant = Array.from(availableHashTagMap.important);
        setImportantAvailable(availableImportant.filter(x => x[1] < items.length).map(x => x[0]));

        const standard = Array.from(hashtagMap.standard);
        setStandardIgnore(standard.filter(x => x[1] < items.length).map(x => x[0]));
        setStandardSelected(standard.filter(x => x[1] === items.length).map(x => x[0]));

        const availableStandard = Array.from(availableHashTagMap.standard);
        setStandardAvailable(availableStandard.filter(x => x[1] < items.length).map(x => x[0]));
    }, []);

    const valid = (value: string, selected: Array<string>): { success: true } | { success: false; error: TranslationKey } => {
        if (!value) return { success: false, error: t('hashtags.error-empty-hashtag') };
        if (!/^[^#]\S*?$/.test(value)) return { success: false, error: t('hashtags.error-invalid-hashtag') };
        if (selected.includes(value)) return { success: false, error: t('hashtags.error-hashtag-already-added') };
        return { success: true };
    };

    const addImportant = (value: string) => {
        const check = valid(value, importantSelected);
        if (!check.success) return new NotifyUserMessage(check.error);

        setImportantValue('');
        setImportantSelected([...importantSelected, value]);
        setImportantAvailable(importantAvailable.filter(x => x !== value));
        (document.getElementsByClassName('important-hashtag-input')[0] as HTMLInputElement).focus();
        (document.getElementsByClassName('important-hashtag-input')[0] as HTMLInputElement).blur();
        (document.getElementsByClassName('important-hashtag-input')[0] as HTMLInputElement).focus();
    };

    const addStandard = (value: string) => {
        const check = valid(value, standardSelected);
        if (!check.success) return new NotifyUserMessage(check.error);

        setStandardValue('');
        setStandardSelected([...standardSelected, value]);
        setStandardAvailable(standardAvailable.filter(x => x !== value));
    };

    const removeImportant = (hashtag: string) => {
        setImportantSelected(importantSelected.filter(x => x !== hashtag));
        setImportantAvailable([...importantAvailable, hashtag]);
    };

    const removeStandard = (hashtag: string) => {
        setStandardSelected(standardSelected.filter(x => x !== hashtag));
        setStandardAvailable([...standardAvailable, hashtag]);
    };

    saveButton.saveText = 'general.save-button';
    saveButton.save = async () => {
        const importantTabAndCurrentTagsDontIncludeInProgress = tab === TabIndex.Important && importantSelected.includes(inProgress);
        const standardTabAndCurrentTagsDontIncludeInProgress = tab === TabIndex.Standard && standardSelected.includes(inProgress);
        const useStandardOrImportant = tab === TabIndex.Important ? importantSelected : standardSelected;
        const isValid = valid(inProgress, useStandardOrImportant).success;
        if (inProgress && !isValid) {
            new NotifyUserMessage(t('hashtags.error-invalid-hashtag'));
            return;
        }
        if (inProgress && (importantTabAndCurrentTagsDontIncludeInProgress || standardTabAndCurrentTagsDontIncludeInProgress)) {
            new NotifyUserMessage(t('hashtags.potential-duplicate-found'));
            return;
        }

        if (inProgress) {
            if (tab === TabIndex.Important) {
                importantSelected.push(inProgress);
            } else if (tab === TabIndex.Standard) standardSelected.push(inProgress);
        }

        if (!items.length) await okCallback({ items, availableHashTagMap });

        for (const initialItem of items) {
            await updateTags(initialItem, importantSelected, standardSelected, importantIgnore, standardIgnore);
        }
        okCallback({ items, availableHashTagMap });
    };

    return (
        <Paper sx={{ p: 2, height: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tab} onChange={(_, v) => setTab(v)} aria-label="">
                    <Tab label={t('hashtags.important-label')} />
                    <Tab label={t('hashtags.standard-label')} />
                </Tabs>
            </Box>
            {tab === TabIndex.Important && (
                <HashtagAutoComplete
                    setInProgress={setInProgress}
                    add={addImportant}
                    options={importantAvailable}
                    remove={removeImportant}
                    value={importantValue}
                    selected={importantSelected}
                    inputClassName="important-hashtag-input"
                />
            )}
            {tab === TabIndex.Standard && (
                <HashtagAutoComplete
                    setInProgress={setInProgress}
                    add={addStandard}
                    options={standardAvailable}
                    remove={removeStandard}
                    value={standardValue}
                    selected={standardSelected}
                    inputClassName="standard-hashtag-input"
                />
            )}
        </Paper>
    );
};
