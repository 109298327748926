import type { ISocialAuthProviderConfig, TranslationKey } from '@nexdynamic/squeegee-common';
import { Account, SocialAuthProviders } from '@nexdynamic/squeegee-common';
import type { Subscription } from 'aurelia-event-aggregator';
import { Aurelia, computedFrom, inject } from 'aurelia-framework';
import moment from 'moment';
import { ApplicationEnvironment } from '../ApplicationEnvironment';
import { ApplicationState } from '../ApplicationState';
import { Data } from '../Data/Data';
import { SqueegeeLocalStorage } from '../Data/SqueegeeLocalStorage';
import { AddressLookupDialog } from '../Dialogs/AddressLookupDialog';
import { LoaderEvent } from '../Events/LoaderEvent';
import { GlobalFlags } from '../GlobalFlags';
import { LanguagesAndCountries } from '../LanguagesAndCountries';
import { Logger } from '../Logger';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { RemoteSupport } from '../RemoteSupport';
import { Api } from '../Server/Api';
import { RethinkDbAuthClient } from '../Server/RethinkDbAuthClient';
import { Utilities, animate, openSystemBrowser } from '../Utilities';
import type { DeveloperLaunchOptionsDialog } from './DeveloperLaunchOptionsDialog';
import { ForgotPasswordDialog } from './ForgotPasswordDialog';

@inject(Aurelia)
export class Launch {
    protected enableRemoteSupport = () => RemoteSupport.enableRemoteSupport();
    protected disableRemoteSupport = () => RemoteSupport.disableRemoteSupport();
    showAppleWontPlayNice: boolean;
    protected get remoteSupportInitialised() {
        return RemoteSupport.remoteSupportInitialised;
    }

    public signInForm: HTMLFormElement;
    public stateFlags = ApplicationState.stateFlags;
    public showSignInForm = true;
    protected account: Account & { password: string } = <any>{};
    protected isMobile = GlobalFlags.isMobile;
    protected isHttp = GlobalFlags.isHttp;
    protected notifySub: Subscription;
    protected error = '';
    protected appleApp: boolean = GlobalFlags.isAppleMobileApp;
    protected logoUrl: string;

    @computedFrom('_api.isConnected')
    protected get connected() {
        return window.navigator.onLine;
    }

    @computedFrom('ApplicationState.version', 'Api.connectionData')
    protected get connectionData() {
        return `Squeegee v${ApplicationState.version} ${Api.connectionData}`;
    }

    protected betaBanner = window.sq.betaVersion;

    constructor(public aurelia: Aurelia) {
        let accountLanguageCode = 'en';
        let accountCountryCode = 'GB';

        const detectedLocale: string = window && window.navigator && (window.navigator.language || (<any>window.navigator).userLanguage);
        if (detectedLocale && detectedLocale.length >= 2) {
            const languages = LanguagesAndCountries.languages;
            const detectedLanguageCode = detectedLocale.substring(0, 2);
            if (languages[detectedLanguageCode]) accountLanguageCode = detectedLanguageCode;
            if (detectedLocale.length === 5) {
                const countries = LanguagesAndCountries.countries;
                const detectedCountryCode = detectedLocale.substring(3, 5);
                if (countries[detectedCountryCode]) accountCountryCode = detectedCountryCode;
            }
        }
        const localeCode = `${accountLanguageCode}-${accountCountryCode}`;
        moment.locale(localeCode);
        ApplicationEnvironment.i18nInstance.setLocale(localeCode);

        if (!GlobalFlags.isMobileApp && window.navigator?.onLine) {
            const isInfinite = window?.location?.hostname?.endsWith('.sqgee.com');
            const isLocalDev = window.location.hostname === 'localhost' && window.location.port;
            const isDev = window.location.hostname === 'dev.squeeg.ee';
            const isStaging = window.location.hostname === 'staging.squeeg.ee';
            if (isInfinite || isLocalDev || isDev || isStaging) this.logoUrl = `/api/themes/logo`;
        }
    }

    protected canRegister = ApplicationState.isAppleApproved;

    protected getSupport() {
        ApplicationState.getSupport('Sign In/Up Help', false, '', 'support-form.squeegee-support');
    }

    protected emailField?: HTMLInputElement;
    protected passwordField?: HTMLInputElement;
    public async attached() {
        const email = document.location.search.match(/(?:[?&]user=)(.*?)(?:$|&)/)?.[1];
        if (email) {
            this.account.email = email;

            const password = document.location.search.match(/(?:[?&]pwd=)(.*?)(?:$|&)/)?.[1];
            if (password) this.account.password = password;

            const url = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
            window.history.replaceState(undefined, document.title, url);
        }

        this.signInDomain = Api.apiEndpoint.replace('https://', '').replace('http://', '').replace('.my.sqg.ee', '');

        this.initSocialAuthProviders();

        this.predictLocale();
        await this.updateCountryOrLanguage();

        this.setValidity('email', true);
        this.setValidity('password', true);

        if (document.location && document.location.pathname.startsWith('/signup')) {
            this.showSignUp();
        } else {
            this.showSignIn();
        }

        const matches = /^\/signup\/([_-\w]*?)$/i.exec((document.location && document.location.pathname) || '');
        if (matches) this.account.referrer = matches[1];
        this.automaticallyReferred = !!matches;

        this.watchForSignInFromOtherTabs();

        this.notifySub = NotifyUserMessage.subscribe((evt: NotifyUserMessage) => {
            this.error = evt.text;
            this.signInForm.classList.toggle('shake');
            setTimeout(() => this.signInForm.classList.toggle('shake'), 500);
        });

        animate().then(() => (email ? this.passwordField?.focus() : this.emailField?.focus()));
        // SlideGenerator.showLaunchSlides();
    }

    private signInTimeoutHandle: any;
    private initSocialAuthProviders() {
        if (GlobalFlags.isAppleMobileDevice) {
            Api.ping().then(ping => {
                if (!ping?.versionInfo?.reviewed && !GlobalFlags.emulateReviewed) return;

                this.signInSocialProviders = SocialAuthProviders.getConfig();
                this.registerSocialProviders = SocialAuthProviders.getConfig();
            });
        } else {
            this.signInSocialProviders = SocialAuthProviders.getConfig();
            this.registerSocialProviders = SocialAuthProviders.getConfig();
        }
    }

    private watchForSignInFromOtherTabs() {
        if (!GlobalFlags.isHttp || GlobalFlags.isAndroidMobileDevice || GlobalFlags.isAppleMobileDevice) return;

        clearTimeout(this.signInTimeoutHandle);

        if (SqueegeeLocalStorage.getItem('squeegee-session') !== null) {
            new LoaderEvent(true, false, 'general.signing-in');
            return setTimeout(() => {
                Logger.info('Sign In From Other Tab Detected');
                this.onSignedIn();
            }, Utilities.randomInteger(100, 500));
        } else {
            this.signInTimeoutHandle = setTimeout(() => this.watchForSignInFromOtherTabs(), 500);
        }
    }

    public detached() {
        this.notifySub && this.notifySub.dispose();
        if (this.signInTimeoutHandle) clearTimeout(this.signInTimeoutHandle);
    }

    public showSignUp() {
        clearTimeout(this.signInTimeoutHandle);

        this.showSignUpForm = true;
        this.showSignInForm = false;

        //this.setValidity('name', true);
        this.setValidity('sign-up-email', true);
        this.setValidity('sign-up-password', true);
        this.error = '';
    }

    public showSignIn() {
        this.showSignUpForm = false;
        this.showSignInForm = true;
        this.setValidity('email', true);
        this.setValidity('password', true);
        this.error = '';
    }

    public validateSignInForm() {
        if (this.signInForm.checkValidity()) return true;

        this.setValidity('email', !!this.account.email && Account.EMAIL_REGEX.test(this.account.email));
        this.setValidity('password', !!this.account.password);
        this.signInForm.classList.toggle('shake');
        setTimeout(() => this.signInForm.classList.toggle('shake'), 500);
        return false;
    }

    private async _validateDomain() {
        if (!this.isEditingSignInDomain) return true;
        if (/^(?![a-z0-9-.]*$)/i.test(this.signInDomain)) return false;
        const isFQDN = this.signInDomain.includes('.');

        const endPoint = isFQDN ? `https://${this.signInDomain}` : `https://${this.signInDomain}.my.sqg.ee`;

        if (!this.signInDomain) {
            await Api.overrideApiEndpoint();
            this.signInDomain = Api.apiEndpoint.replace('https://', '').replace('http://', '').replace('.my.sqg.ee', '');
            this.isEditingSignInDomain = false;
            return true;
        } else {
            const pingResult = await Api.ping(4000, endPoint);
            if (pingResult?.versionInfo.pong) {
                ApplicationState.apiEndpointOverride = endPoint;
                this.isEditingSignInDomain = false;
                return true;
            }
        }
        return false;
    }

    public async signIn() {
        new LoaderEvent(true);
        try {
            Data.reset();
            if (!this.validateSignInForm()) return;

            if (!(await this._validateDomain())) {
                this.error = 'Your MySqueegee™ host was not found. Please check you entered it correctly.';
                this.signInForm.classList.toggle('shake');
                setTimeout(() => this.signInForm.classList.toggle('shake'), 500);
                return;
            }

            const signInResult = await RethinkDbAuthClient.signin(this.account.email, this.account.password, true);
            if (signInResult.signedIn) this.onSignedIn();
            else if (signInResult.userDoesNotExist && GlobalFlags.isAppleMobileDevice) this.showAppleWontPlayNice = true;
            else {
                this.error = signInResult.error || 'There was an error during sign in, please try again';
                this.signInForm.classList.toggle('shake');
                setTimeout(() => this.signInForm.classList.toggle('shake'), 500);
            }

            new LoaderEvent(false);
        } catch (e) {
            this.error = 'There was an error during sign in, please try again';
            this.signInForm.classList.toggle('shake');
            setTimeout(() => this.signInForm.classList.toggle('shake'), 500);
        } finally {
            new LoaderEvent(false);
        }
    }

    okAppleWontPlayNice() {
        this.showAppleWontPlayNice = false;
    }

    private onSignedIn() {
        clearTimeout(this.signInTimeoutHandle);
        new LoaderEvent(false);
        ApplicationState.setRootStartup();
    }

    protected async signInWithApple(mode: 'web' | 'app') {
        let tries = 3;
        let signedIn = false;
        let lastError = '';
        while (tries--) {
            signedIn = await new Promise<boolean>(resolve => {
                try {
                    cordova.plugins.SignInWithApple.signin(
                        { requestedScopes: [0, 1] },
                        async (result: AppleSignInResult) => {
                            new LoaderEvent(true);

                            const signedIn = await RethinkDbAuthClient.signInOAuth('apple', {
                                code: result.authorizationCode,
                                id_token: result.identityToken,
                                state: result.state,
                                client_id: 'Squeegee',
                                mode,
                                native: GlobalFlags.isAppleMobileApp || undefined,
                            });
                            if (signedIn) this.onSignedIn();
                            lastError = '';
                            resolve(true);
                        },
                        (error: AppleSignInError) => {
                            if ((error?.code || '') === '1001') {
                                Logger.error('User cancelled sign in with Apple ID', error);
                                return resolve(true);
                            }
                            Logger.error('Login with apple failed.', error);
                            const errorMessage = `${error.localizedDescription} (${error.localizedFailureReason}) code: ${error.code} error: ${error.error}.`;
                            new NotifyUserMessage(errorMessage as TranslationKey);
                            lastError = errorMessage + '\n';
                            resolve(false);
                        }
                    );
                } catch (error) {
                    Logger.error('Login with apple failed to call.', error);
                    const errorMessage = `${error.message}`;
                    new NotifyUserMessage(errorMessage as TranslationKey);
                    lastError = errorMessage + '\n';
                    resolve(false);
                }
            });
            if (signedIn) break;
        }
        if (!signedIn) {
            new NotifyUserMessage('notification.apple-sign-in-failed-error', { error: lastError });
        }
    }

    public async socialAuth(provider: string) {
        Data.reset();
        if (!(await this._validateDomain())) {
            this.error = 'Domain is not valid';
            this.signInForm.classList.toggle('shake');
            setTimeout(() => this.signInForm.classList.toggle('shake'), 500);
            return;
        }
        if (provider === 'apple' && GlobalFlags.isAppleMobileApp) this.signInWithApple('app');
        else {
            openSystemBrowser(
                `${Api.apiEndpoint}/api/authentication/social/${provider}/init?referrer=${this.account.referrer}&mode=${
                    this.isHttp ? 'web' : 'app'
                }`,
                undefined,
                true
            );
        }
    }

    protected signInDomain = '';
    signInDomainChanged() {
        Api.overrideApiEndpoint(this.signInDomain);
    }

    protected isEditingSignInDomain = false;

    protected editSignInDomain() {
        this.isEditingSignInDomain = true;
    }
    public async forgotPassword() {
        const dialog = new ForgotPasswordDialog(this.account.email);
        await dialog.show();
    }

    public async decrementDevModeCounter() {
        await ApplicationState.decrementShowDevModeCounter();
    }

    private devMode?: DeveloperLaunchOptionsDialog;
    protected toggleDevMode() {
        if (this.devMode) {
            this.devMode.cancel();
            delete this.devMode;
        } else {
            if (ApplicationState.stateFlags.devMode) {
                this.devMode = ApplicationState.openDevModeDialog();
            }
        }
    }

    protected countries = LanguagesAndCountries.countriesArray;
    protected languages = LanguagesAndCountries.languagesArray;
    protected signupForm: HTMLFormElement;
    protected nameInput: HTMLFormElement;
    protected showSignUpForm = false;
    protected showVerifyEmailStep: boolean;
    protected registeredVerifyStep: boolean;
    protected automaticallyReferred: boolean;
    protected supportEmail = ApplicationState.localise('general.support-email');
    protected countryCode: string;
    protected languageCode: string;
    protected refreshingLocale = false;

    protected ensureTitleCase() {
        this.account.name = Utilities.toTitleCase(this.account.name);
        return true;
    }

    protected async updateCountryOrLanguage() {
        if (LanguagesAndCountries.languagesArray.map(x => x.value).indexOf(this.languageCode) === -1) {
            this.languageCode = 'en';
        }

        const localeCode = `${this.languageCode}-${this.countryCode}`;
        this.account.language = localeCode;
        moment.locale(localeCode);
        await ApplicationEnvironment.i18nInstance.setLocale(localeCode);
        this.countries = LanguagesAndCountries.countriesArray;
        this.languages = LanguagesAndCountries.languagesArray;
    }

    public async setAddress(event: Event) {
        const dialog = new AddressLookupDialog(this.account.address);
        const location = await dialog.show();
        if (!dialog.cancelled) {
            this.account.address = location;
            this.account.businessAddress = location;
        }
        if (event) event.stopPropagation();
    }

    protected signInSocialProviders: Array<ISocialAuthProviderConfig>;
    protected registerSocialProviders: Array<ISocialAuthProviderConfig>;

    protected sendConfirmEmail() {
        RethinkDbAuthClient.resendConfirmationEmail(this.account.email);
        new NotifyUserMessage('notifications.sign-up-confirm-email-sent', { email: this.account.email });
    }

    public validateSignUpForm() {
        if (this.signupForm.checkValidity()) return true;
        this.setValidity('sign-up-name', !!this.account.name);
        this.setValidity('sign-up-email', !!this.account.email && Account.EMAIL_REGEX.test(this.account.email));
        this.setValidity('sign-up-password', !!this.account.password);
        this.signupForm.classList.toggle('shake');
        setTimeout(() => this.signupForm.classList.toggle('shake'), 500);
        return false;
    }

    public signUpAgain() {
        this.showVerifyEmailStep = false;
        this.showSignUpForm = true;
    }

    public async signUp() {
        if (!this.validateSignUpForm()) return;
        new LoaderEvent(true);
        const registered = await RethinkDbAuthClient.register(
            this.account.name,
            this.account.email,
            this.account.isOptedInToMarketing,
            this.account.password,
            this.account.language,
            this.account.referrer,
            this.account.contactNumber ? this.account.contactNumber : ''
        );

        if (registered) {
            const signInResult = await RethinkDbAuthClient.signin(this.account.email, this.account.password, true);
            if (signInResult.signedIn) this.onSignedIn();
            new LoaderEvent(false);
            //this.showVerifyEmailStep = true;
        } else {
            new LoaderEvent(false);
        }
    }

    protected async signInFromSignUp() {
        const signInResult = await RethinkDbAuthClient.signin(this.account.email, this.account.password, true);
        if (signInResult.signedIn) {
            await ApplicationState.setRootStartup();
        } else {
            new NotifyUserMessage('notification.unable-to-sing-in-from-sign-up');
            Logger.error('Unable to sign in from sign up');
        }
    }

    private setValidity(fieldId: string, valid: boolean) {
        const container = <HTMLDivElement>(<HTMLInputElement>document.getElementById(fieldId)).parentElement;
        if (valid) container.classList.remove('is-invalid');
        else container.classList.add('is-invalid');
    }

    private predictLocale() {
        const locale = ApplicationState.predictLocale();
        this.languageCode = locale.languageCode;
        this.countryCode = locale.countryCode;
    }
}
