import type { BackupInitiatorTask, StoredObject, TranslationKey } from '@nexdynamic/squeegee-common';
import { SyncMode, wait } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { AuditManager } from '../AuditManager';
import { Prompt } from '../Dialogs/Prompt';
import { prompt } from '../Dialogs/ReactDialogProvider';
import { LoaderEvent } from '../Events/LoaderEvent';
import { Logger } from '../Logger';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { Api } from '../Server/Api';
import { RethinkDbAuthClient } from '../Server/RethinkDbAuthClient';
import { unknownLengthBackgroundTask } from '../Utilities';
import { t } from '../t';
import { Data } from './Data';
import { backupServer } from './backupServer';

type BackupEmailOptions = {
    sendBackupToEmail?: boolean;
    sendBackupToEmailBody?: string;
    sendBackupToEmailSubject?: string;
};

export const createBackup = async (options?: {
    suppressErrorDialog?: boolean;
    initiatorTask?: BackupInitiatorTask;
    auditInfo?: { actionDetailsDescription?: string; items: StoredObject[] };
    backupEmailOptions?: BackupEmailOptions;
}): Promise<boolean> => {
    const { suppressErrorDialog } = options ?? {};
    new LoaderEvent(true, true, 'backups.performing-server-side-backup');
    const { done } = unknownLengthBackgroundTask('backups.performing-server-side-backup');
    try {
        const backgrounded = setTimeout(() => {
            new NotifyUserMessage('backups.performing-server-side-backup');
            new LoaderEvent(false);
        }, 2000);

        const body = {
            initiatorTask: options?.initiatorTask,
            sendBackupToEmail: options?.backupEmailOptions?.sendBackupToEmail,
            sendBackupToEmailBody: options?.backupEmailOptions?.sendBackupToEmailBody,
            sendBackupToEmailSubject: options?.backupEmailOptions?.sendBackupToEmailSubject,
        };

        const result = await Api.post<{ success: true } | { success: false; error: string }>(
            backupServer(),
            '/api/backups',
            body,
            undefined,
            false,
            { timeout: 600000 }
        ).catch(error => {
            Logger.error('Failed to create backup', { error });
            return { data: { success: false, error: t('settings.backups-error') } };
        });
        AuditManager.recordAuditEvent({
            action: 'backup',
            items: options?.auditInfo?.items ?? [],
            actionDetailsDescription: options?.auditInfo?.actionDetailsDescription,
            actionDetailsSummary: options?.initiatorTask,
        });
        new LoaderEvent(false);
        if (!result) {
            clearTimeout(backgrounded);
            if (!suppressErrorDialog) await prompt('general.error', 'settings.backups-error', { cancelLabel: '' }).show();
            return false;
        } else if (!result.data.success) {
            clearTimeout(backgrounded);
            if (!suppressErrorDialog) await prompt('general.error', t(result.data.error as TranslationKey), { cancelLabel: '' }).show();
            return false;
        }
    } catch (error) {
        Logger.error(error);
        await prompt('general.error', 'settings.backups-error', { cancelLabel: '' }).show();
    } finally {
        done();
    }

    new NotifyUserMessage('settings.backup-created');
    return true;
};

export const restoreBackup = async (backup: { id: string; date: string }) => {
    try {
        if (!RethinkDbAuthClient.session) return;

        // Confirm restore backup
        const areYouSure = new Prompt('prompts.confirm-server-restore', 'prompts.confirm-server-restore-text', {
            okLabel: 'general.yes',
            cancelLabel: 'general.no',
            localisationParams: {
                date: moment(backup.date).format('dddd MMMM Do, h:mma'),
            },
        });
        const youAreSure = await areYouSure.show();
        if (!youAreSure) return;

        new LoaderEvent(true, true, 'settings.restoring-backup-on-server');

        await Api.post(backupServer(), `/api/backups/${backup.id}/restore`, undefined, undefined, false, { timeout: 600000 });

        new NotifyUserMessage('settings.backup-restore-syncing');
        await wait(5000);
        await Data.fullSync(SyncMode.FullClearCache);
        new LoaderEvent(false);
        await new Prompt('prompts.success-title', 'prompts.success-restore-backup-text', { cancelLabel: '' }).show();
        window.location.reload();
    } catch (error) {
        Logger.error('Failed to restore backup', { error });
    }
};
