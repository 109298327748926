import type { Notification } from '@nexdynamic/squeegee-common';
import {
    sortByCreatedDateAsc,
    sortByCreatedDateDesc,
    type Customer,
    type CustomerNotificationMethodTypes,
    type TranslationKey,
} from '@nexdynamic/squeegee-common';
import { bindable } from 'aurelia-framework';
import { ApplicationState } from '../../ApplicationState';
import { CustomerService } from '../../Customers/CustomerService';
import { Data } from '../../Data/Data';
import { DialogAnimation } from '../../Dialogs/DialogAnimation';
import { Prompt } from '../../Dialogs/Prompt';
import { Select } from '../../Dialogs/Select';
import { SendMessageToCustomer } from '../../Dialogs/SendMessageToCustomer';
import { DataRefreshedEvent } from '../../Events/DataRefreshedEvent';
import type { Subscription } from '../../Events/SqueegeeEventAggregator';
import { Logger } from '../../Logger';
import type { IMessage, INewMessage } from '../../Messages';
import { Message, MessageUtils } from '../../Messages';
import { NotificationService } from '../../Notifications/NotificationService';
import { NotifyUserMessage } from '../../Notifications/NotifyUserMessage';
import { fetchArchivedCustomerConversation } from '../../Notifications/functions/fetchCustomerConversation';
import { Api } from '../../Server/Api';

export class CustomerMessages {
    @bindable() customer: Customer;
    @bindable hasContactMethods: boolean;
    protected messages: Array<Message> = [];
    private markAsReadTimeout?: ReturnType<typeof setTimeout>;
    protected loading = false;
    private refreshEvent: Subscription;
    attached() {
        // if a different customer is selected, all this gets reset and loads in messages (from local plus fetching archived from server)
        // detaching and reattaching can occur when leaving this view even toggling customer views for the same customer
        // have set it so 'skip' 'loading', 'moreToLoad' and 'messages' list are not reset on detach - they will stay in the list in memory
        // this means archived messages are not need to be fetched again from server whilst this particular customer is selected if coming back to this view
        // and 'loadNotifications' will update the message list when message view is selected with any new messages or statuses that have beeb synced to device in the meantime
        // even though the subscription was cancelled on the detach
        this.loadNotifications();
        this.refreshEvent = DataRefreshedEvent.subscribe((event: DataRefreshedEvent) => {
            // Watch for local notifications
            const notifications = event
                .filterByType<Notification>('notifications')
                .filter(n => n.customerId === this.customer._id)
                .sort((a, b) => (a.createdDate > b.createdDate ? -1 : 1));
            if (!notifications.length) return;
            for (const notification of notifications) {
                this.addMessages([Message.fromNotification(notification)]);
            }
        });
    }

    detached() {
        // this.loading = false;
        // this.moreToLoad = true;
        // this.skip = 0;
        // this.messages.splice(0, this.messages.length);
        if (this.markAsReadTimeout) clearTimeout(this.markAsReadTimeout);
        if (this.refreshEvent) this.refreshEvent.dispose();
    }

    protected moreToLoad = true;

    private skip = 0;

    private async loadMore() {
        try {
            console.log('load more archived messages check');
            if (!this.moreToLoad) return;
            const take = 25;
            const archivedNotifications = await fetchArchivedCustomerConversation({
                skip: this.skip,
                take,
                customerId: this.customer._id,
            });

            if (archivedNotifications.length >= take) {
                this.moreToLoad = true;
                this.skip += take;
            } else {
                this.moreToLoad = false;
            }

            const newMessages = archivedNotifications.map(Message.fromNotification);

            // Some messages may already exist locally, so we need to merge them
            this.addMessages(newMessages);
        } catch (error) {
            Api.isConnected ? new NotifyUserMessage('messages.offline-notification') : new NotifyUserMessage('messages.unable-to-load');
            Logger.error('Unable to load more messages', error);
        }
    }
    private async loadNotifications() {
        try {
            this.loading = true;
            if (this.customer) {
                const localNotifications = Data.all<Notification>('notifications', { customerId: this.customer._id })
                    .slice()
                    .sort(sortByCreatedDateAsc);
                const newMessages = localNotifications.map(Message.fromNotification);
                this.addMessages(newMessages);
                this.loadMore();
            }
        } catch (error) {
            Logger.error('Unable to load notifications', error);
            return [];
        } finally {
            this.loading = false;
        }
    }

    protected addMessages(newMessages: Array<Message>) {
        //const messagesToAdd: Array<Message> = this.messages.slice();

        for (const message of newMessages) {
            const existingMessage = this.messages.find(m => m.id === message.id);
            if (existingMessage) {
                // Update the existing message with the new message
                Object.assign(existingMessage, message);
            } else {
                this.messages.push(message);
            }
        }
        this.messages.sort(sortByCreatedDateAsc);
    }

    protected customMessage() {
        if (!ApplicationState.isVerifiedForMessaging) throw new Error('Please verify your email to send messages');
        if (this.customer) {
            const dialog = new SendMessageToCustomer(this.customer, undefined, undefined, 'messages.send-message');

            dialog.show(DialogAnimation.SLIDE_UP);
        }
    }
    private _translations = {
        '': 'general.none',
        'email': 'customers.notification-email-label',
        'sms': 'customers.notification-phone-label',
        'sms2': 'customers.notification-alternative-phone-label',
        'email-and-sms': 'customers.notification-email-and-phone-label',
        'email-and-sms2': 'customers.notification-email-and-phone2-label',
    } as { [key: string]: TranslationKey };

    protected async newMessage(message: INewMessage) {
        try {
            if (!ApplicationState.isVerifiedForMessaging) throw new Error('Confirm Email Address to Send Messages');
            if (!message) throw new Error('Message was not defined');
            if (this.customer) {
                if (!this.customer.defaultNotificationMethod) {
                    const notSetDialog = new Prompt(
                        'messages.default-contact-method-required',
                        'messages.default-contact-method-required-description',
                        {
                            cancelLabel: 'general.cancel',
                            okLabel: ApplicationState.localise('notification.set-default-to-question'),
                            localisationParams: { name: this.customer.name },
                        }
                    );
                    await notSetDialog.show(DialogAnimation.GROW);
                    if (notSetDialog.cancelled) return;
                    const options: Array<{ text: TranslationKey; value: string }> = [];

                    for (const value in this._translations) options.push({ text: this._translations[value], value });

                    const dialog = new Select(
                        'dialogs.default-notification-method-title',
                        options,
                        'text',
                        'value',
                        this.customer.defaultNotificationMethod
                    );
                    const result = await dialog.show(DialogAnimation.SLIDE);

                    if (dialog.cancelled) return;

                    if (result.value) {
                        this.customer.defaultNotificationMethod = <CustomerNotificationMethodTypes>result.value;
                    } else this.customer.defaultNotificationMethod = undefined;
                    Data.put(this.customer);
                }

                const sender = MessageUtils.getMessageSender();
                const description = ApplicationState.localise('notifications.description-from', {
                    name: ApplicationState.account.businessName || ApplicationState.account.name || 'your service provider',
                });
                const subject = (message.isConversation && getConversationSubject(this.customer._id)) || undefined;
                await CustomerService.sendMessage(this.customer, {
                    description,
                    senderName: sender,
                    content: message.content,
                    subject,
                });
            } else throw new Error('Customer was not defined');
        } catch (error) {
            Logger.error(`Unable to send message to customer with id ${this.customer._id}`, error);
            new NotifyUserMessage(typeof error === 'string' ? (error as TranslationKey) : 'messages.unable-to-send');
        }
    }

    updateLastRead(message: IMessage) {
        if (!message?.unread) return;
        // mark message as read
        message.unread = false;
        !this.markAsReadTimeout &&
            NotificationService.markAsRead([message.id]).catch(() => {
                Logger.error('updateLastRead Unable to mark message as read');
                message.unread = true; // revert the change
            });
    }
}

export const getConversationSubject = (customerId: string) => {
    const replyingTo = Data.all<Notification>('notifications', { customerId, type: 'EmailReply' }).slice().sort(sortByCreatedDateDesc)[0];
    if (!replyingTo) return;

    return replyingTo.subject;
};
