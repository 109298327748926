import { Logger } from '../../Logger';
import { TABLE_NAME_LEGACY } from './TABLE_NAME_LEGACY';

export const checkLegacyTableExists = async (db: SQLitePlugin.Database): Promise<boolean> => {
    const oldTableStillExists = await new Promise<boolean>(resolve => {
        db.executeSql(
            `SELECT COUNT(*) > 0 AS tableExists FROM sqlite_master WHERE type='table' AND name=?`,
            [TABLE_NAME_LEGACY],
            result => {
                resolve(result.rows.item(0).tableExists === 1);
            },
            error => {
                Logger.error('Error, unable to check if old table exists', { error });
                resolve(false);
            }
        );
    });

    Logger.info(oldTableStillExists ? 'Old table exists' : 'Old table does not exist');

    return oldTableStillExists;
};
