import { ApplicationState } from '../../ApplicationState';

export const getUserDateFormat = (): string => {
    const locale = ApplicationState.instance.account.language;

    // format test date based on locale
    const testDate = new Date(2000, 1, 2); // 2nd February 2000
    const formattedDate = new Intl.DateTimeFormat(locale, { day: '2-digit', month: '2-digit', year: 'numeric' }).format(testDate);

    // return format based on ordering of day, month, and year
    if (formattedDate.startsWith('02') && formattedDate.includes('2000')) {
        return 'DD/MM/YYYY';
    } else if (formattedDate.startsWith('02')) {
        return 'MM/DD/YYYY';
    } else {
        return 'YYYY/MM/DD';
    }
};
